import Vue from "vue";
import axios from "axios";
import JwtService from "@/core/services/jwt.service";

const ApiGmcService = {

  getAuthToken() {
    var call = function(resolve, reject) {
        //console.log("GMC: Authorization ...");
        
        let urlSearchParams = new URLSearchParams()
        urlSearchParams.append('grant_type', 'client_credentials');
        urlSearchParams.append('scope', 'external_services/users.manage');

        let meta = {
            headers: {
                'Authorization': 'Basic ' + JwtService.getGmcAuthToken(),
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }

        axios.post(process.env.VUE_APP_API_GOODYEAR_MOBILITY_CLOUD_AUTHORIZATION, urlSearchParams, meta)
        .then(({ data }) => {
            //console.log("GMC: Token recieved.");
            JwtService.saveGmcToken(data.token_type + ' ' + data.access_token);
            return resolve();
        })
        .catch((response) => {
            console.log("GMC: Authorization failed.");
            return reject(response); 
        });
    };
    return new Promise(call);
  },

  /**
   * Send the GET HTTP request
   */
  get(resource, context) {    
    var call = function(resolve, reject) {
        ApiGmcService.getAuthToken()
        .then(() => {
            Vue.axios.get(process.env.VUE_APP_API_GOODYEAR_MOBILITY_CLOUD_ACCESS_MANAGEMENT + `${resource}`,
                { headers: { 'Authorization': JwtService.getGmcToken(), } })
            .then(({ data }) => {
                return resolve({data});
            })
            .catch(({ response }) => { 
                return reject({response});
            });
        });
    }

    return new Promise(call.bind({context}));
  },

  /**
   * Set the POST HTTP request
   */
  post(resource, params, context) {    
    var call = function(resolve, reject) {
        ApiGmcService.getAuthToken()
        .then(() => {
            Vue.axios.post(process.env.VUE_APP_API_GOODYEAR_MOBILITY_CLOUD_ACCESS_MANAGEMENT + `${resource}`, params,
                { headers: { 'Authorization': JwtService.getGmcToken(), } })
            .then(({ data }) => {
                return resolve({data});
            })
            .catch(({ response }) => {
                return reject({response}); 
            });
        });
    };
  
    return new Promise(call.bind({context}));
  },

  /**
   * Send the UPDATE HTTP request
   */
  update(resource, slug, params, context) {
    var call = function(resolve, reject) {
        ApiGmcService.getAuthToken()
        .then(() => {
            Vue.axios.put(process.env.VUE_APP_API_GOODYEAR_MOBILITY_CLOUD_ACCESS_MANAGEMENT + `${resource}/${slug}`, params,
                { headers: { 'Authorization': JwtService.getGmcToken(), } })
            .then(({ data }) => {
                return resolve({data});
            })
            .catch(({ response }) => {
                return reject({response}); 
            });
        });
    };

    return new Promise(call.bind({context}));
  },

  /**
   * Send the PUT HTTP request
   */
  put(resource, params, context) {
    
    var call = function(resolve, reject) {
        ApiGmcService.getAuthToken()
        .then(() => {
            Vue.axios.put(process.env.VUE_APP_API_GOODYEAR_MOBILITY_CLOUD_ACCESS_MANAGEMENT + `${resource}`, params,
                { headers: { 'Authorization': JwtService.getGmcToken(), } })
            .then(({ data }) => {
                return resolve({data});
            })
            .catch(({ response }) => {
                return reject({response}); 
            });
        })
    };
  
    return new Promise(call.bind({context}));
  },

  /**
   * Send the DELETE HTTP request
   */
  delete(resource, context) {
    var call = function(resolve, reject) {
        ApiGmcService.getAuthToken()
        .then(() => {
            Vue.axios.delete(process.env.VUE_APP_API_GOODYEAR_MOBILITY_CLOUD_ACCESS_MANAGEMENT + `${resource}`, 
                { headers: { 'Authorization': JwtService.getGmcToken(), } })
            .then(() => { 
                return resolve(); 
            })
            .catch(({ response }) => {
                return reject({response}); 
            });
        })
    };

    return new Promise(call);
  },
};

export default ApiGmcService;
