<template>
    <div class="modal-mask">
        <div :class="['modal-dialog', sizeClass ? sizeClass : '']">
            <div class="modal-content">
                <div class="modal-header" v-if="title">
                    <button v-if="isClosable" type="button" class="close" @click="close">x</button>
                    <h4 class="modal-title">{{ $trans(title) }}</h4>
                </div>
                <div class="modal-body">
                    <p :class="{ 'alert alert-danger': isError, 'alert alert-success': isSuccess }" v-if="message">{{ $trans(message) }}</p>
                    <slot name="content"></slot>
                    <div v-if="isWaiting" class="gy-spinner-container">    
                        <span class="gy-spinner-icon"></span>    
                        <div class="preloader-wrapper big active">        
                            <div class="spinner-layer spinner-blue-only">            
                                <div class="circle-clipper left">                
                                    <div class="circle">
                                    </div>            
                                </div>
                                <div class="gap-patch">                
                                    <div class="circle">
                                    </div>            
                                </div>
                                <div class="circle-clipper right">                
                                    <div class="circle">
                                    </div>            
                                </div>        
                            </div>    
                        </div>    
                        <br>
                    </div>
                </div>
                <div v-if="$scopedSlots.buttons || $slots.buttons" class="modal-footer">
                    <slot name="buttons" :close="close" :ok="ok"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String
        },
        message: {
            type: String
        },
        sizeClass: {
            type: String,
            default: "",
        },
        isError: {
            type: Boolean,
            default: false
        },
        isSuccess: {
            type: Boolean,
            default: false
        },
        isClosable: {
            type: Boolean,
            default: true
        },
        isWaiting: {
            type: Boolean,
            default: false
        },
        isOutsideClickable: {
            type: Boolean,
            default: false
        },
        noBodyOverflow: Boolean,
    },
    mounted() {
        window.addEventListener("click", this.checkOutsideClick);

        if(this.noBodyOverflow) {
            document.body.classList.add("modal-open");
        }
    },
    beforeDestroy() {
        if(this.noBodyOverflow) {
            document.body.classList.remove("modal-open");
        }
        
        window.removeEventListener("click", this.checkOutsideClick);
    },
    methods: {
        close() {
            this.$emit("close");
        },
        ok() {
            this.$emit("ok")
        },
        checkOutsideClick(event) {
            if(this.isOutsideClickable && event.target.className == "modal-mask") {
                this.$emit("close");
            }
        }
    }
}
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /*display: table;*/
    transition: opacity 0.3s ease;
    overflow-y: auto;
    overflow-x: hidden;
}
.modal-header {
    display: block !important;
    border-radius: 0;
}
.modal-header .modal-title {
    width: 85%;
    overflow: hidden;
}
.modal-footer {
    display: block !important;
}
.modal-dialog.w-100 {
    width: 100%;
    max-width: none;
}
.modal-dialog.w-75 {
    max-width: 75vw;
    width: auto;
}
.modal-dialog.w-80 {
    max-width: 80vw;
    width: auto;
}
.btn-default {
    padding: 6px 12px;
}
@media (max-width: 768px) {
    .modal-dialog.w-100 {
        width: auto;
    }
    .modal-dialog.w-75 {
        width: auto !important;
    }   
    .modal-dialog.w-80 {
        width: auto;
    }   
    .modal-dialog {
        padding: 15px 5px;
        max-width: 98vw !important;
        width: auto;
        margin: 1.75rem auto;
    }
    .modal-content {
        position: relative;
        left: 0px;
        right: 0;
        top: 0;
        margin: 0;
    }
}
</style>
