<template>
    <div id="terms-popup">
        <popup v-if="showPopup"
            title="Terms and conditions"
            message="Please review the below link to view Goodyear's Global Privacy Policy:"
            @close="close"
            >

            <template v-slot:content>
                <div class="form-group">
                    <a href="https://corporate.goodyear.com/en-US/terms-conditions-and-privacy-policy/global-privacy-policy.html" target="_blank">https://corporate.goodyear.com/en-US/terms-conditions-and-privacy-policy/global-privacy-policy.html</a>
                </div>
                <div class="form-group">

                    <div class="gy-checkbox" style="border-bottom: none">
                        <input type="checkbox" id="accept-ta" class="material-checkbox" value="true" v-model="status">
                        <label for="accept-ta"  class="material-checkbox-label">
                            Do you acknowledge that you have access and review the Privacy Policy and Terms of Use
                        </label>
                    </div>  

                    
                </div>
            </template>
            <template v-slot:buttons="">
                <button type="button" class="btn gy-btn-alt" @click="close">Log out</button>
                <button type="button" class="btn gy-btn" :disabled="isDisable" @click="submit">Submit</button>
            </template>

        </popup>

    </div>
</template>

<script>
// api
import { mapGetters, mapActions } from "vuex";
import {API_ACTION_AGREE_WITH_FHQ_TERMS} from "@/core/services/api/admin.fleetHQTerms.module";
import { API_USER_REDIRECT } from "@/core/services/api/auth.module"
// components
import Popup from '@/components/Popup.vue';

export default {
    data() {
        return {
            showPopup: true,
            status: false,
        }
    },
    methods: {
        // showTermsPopup: function() {
        //     document.body.classList.add("terms-popup-open");
        //     document.getElementById("terms-popup").classList.add("show");
        //     this.showPopup = true;
        // },
        // closeTermsPopup: function() {
        //     document.body.classList.remove("terms-popup-open");
        //     document.getElementById("terms-popup").classList.remove("show");
        // },
        // close() {
        //     this.showPopup = false;
        //     this.$root.$emit('close-terms-popup');
        //     // this.logout();
        // },
        submit() {
            //console.log('submit');
            this.$root.$emit('show-loader', 'Loading...');
            //console.log('status = ' + this.status);
            this.$store.dispatch(API_ACTION_AGREE_WITH_FHQ_TERMS, this.status)
            .then(() => {
                //console.log(this.Status.status);
                this.$root.$emit('close-loader');
                if(this.Status.status === 'Success') {
                    this.showPopup = false;
                    this.$emit('close', false);
                    this.$store.dispatch(API_USER_REDIRECT);
                }
            })
            .catch(e => {
                console.log(e);
                this.$root.$emit('close-loader');
                this.logout();
            });
        },
        close() {
            this.showPopup = false;
            this.$emit('close', false);
            this.logout();
        },
        ...mapActions([
            "logout"
        ]),
        test(x) {
            console.log(x);
        }
    },
    components: {
        'popup': Popup
    },
    computed: {
        // eslint-disable-next-line
        isDisable() {
            if (this.IsAuthenticated == true) {
                return !this.status;
            }
        },
        ...mapGetters({
            Status: "getAgreeWithFHQTermsStatus",
            IsAuthenticated: "AppIsAuthenticated"
        })  
    },
    created() {
        var div = document.createElement('div');
        div.className = "popup-backdrop";
        document.body.appendChild(div);

        // this.$root.$on('show-terms-popup', data => {
        //     this.showTermsPopup();
        // });
        // this.$root.$on('close-terms-popup', data => {this.closeTermsPopup(); });
    },
    beforeDestroy() {
        this.$root.$off('show-terms-popup');
        this.$root.$off('close-terms-popup');
    }
}
</script>

<style>
    .popup-backdrop {
        display: none;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        position: fixed;
        z-index: 2040;
        background-color: #000;
        opacity: 0.5;
    }
    body.terms-popup-open {
        overflow: hidden;
    }
    body.terms-popup-open .popup-backdrop{
        display: block;
    }

    /* #terms-popup {
        position: absolute;
        z-index: 2041;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: none;
    } */

    body.terms-popup-open {
        display: block;
    }

    #terms-popup .loader-title {
        font-size: 2em;
        font-weight: 100;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
    }
</style>