<template>
    <div class="gy-range-input">
        <div class="gy-range-input-name">
            <slot name="default"></slot>
        </div>
        <div class="gy-range-slider">
            <vue-slider 
                v-model="__vmodel"
                :tooltip="tooltip"    
                :data="ticks"
                :disabled="disabled"
            >
            </vue-slider>
        </div>
        <div class="gy-range-values-container">
            <div class="gy-range-value">
                <input type="text" class="form-control" disabled :value="_displayValueFrom">
            </div>
            <div class="gy-range-value">
                <input type="text" class="form-control" disabled :value="_displayValueTo">
            </div>
        </div>
    </div>    
</template>

<script>
    import VueSlider from 'vue-slider-component'
    import 'vue-slider-component/theme/default.css'

    export default {
        props: {
            value: {
                type: Array
            },
            ticks: Array,
            tooltip: {
                type: String,
                default: 'none'
            },
            displayFrom: {
                type: Function,
                default: null
            },
            displayValue: {
                type: Function,
                default: (value) => value
            },
            disabled: {
                type: Boolean,
                default: false
            },
        },
        mounted() {

        },
        data() {
            return {

            }
        },
        computed: {
            __vmodel: {
                get() {
                    return this.value;
                },
                set(v) {
                    this.$emit('input', v);
                }
            },
            _min() {
                return this.ticks[0];
            },
            _max() {
                return this.ticks[this.ticks.length - 1];
            },
            _displayValueFrom() {
                return this.disabled ? '-' : this.displayValue(this.__vmodel[0]);
            },
            _displayValueTo() {
                return this.disabled ? '-' : this.displayValue(this.__vmodel[1]);
            },
        },
        methods: {
            
            
            
        },
        watch: {
            disabled(){
                this.__vmodel = [this._min, this._max];       
            },
        },
        components: {
            VueSlider
        }
    }
</script>

<style>
    .gy-range-input {
        display: block;
    }
        .gy-range-input .gy-range-slider .vue-slider-dot-handle {
            border: 1px solid #c5c5c5;
        }
        .gy-range-input .gy-range-slider .vue-slider-dot-handle-focus {
            border: 1px solid #c5c5c5;
            box-shadow: 0px 0px 1px 2px rgba(39,75,167, 0.36);
        }
        .gy-range-input .gy-range-slider .vue-slider-process {
            background-color: #274ba7;
        }

        .gy-range-input .gy-range-values-container {
            position: relative;
            display: flex;
            justify-content: space-between;
            margin-top: 6px;
        }
            .gy-range-input .gy-range-values-container .gy-range-value {
                display: inline-block;
                width: 100px;
            }

        .gy-range-input .gy-range-input-name {
            font-weight: 700;
            font-size: 14px;
            margin-bottom: 6px;
        }

    .gy-checkbox.all-events {
        height: 35px;
    }

        .gy-checkbox.all-events .custom-control.custom-checkbox {
            padding-left: 10px;
        } 
</style>