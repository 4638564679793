import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import store from "../store";

// action types
export const API_DEALER_PORTAL_STATISTIC_GET = "action_dealer_portal_statistic_get";
export const API_DEALER_PORTAL_DEALERS_GET = "action_dealer_portal_dealers_get";
export const API_DEALER_PORTAL_DEALER_LOCATIONS_GET = "action_dealer_portal_dealer_location_get";
export const API_DEALER_PORTAL_DEALER_SUB_COMMON_OWNERS_GET = "action_dealer_portal_dealer_sun_common_owners_get";
export const API_DEALER_PORTAL_LOCATIONS_GET = "action_dealer_portal_locations_get";

// muts
export const API_DEALER_PORTAL_MUTS_SET_STATISTIC = "muts_dealer_portal_set_statistic";
export const API_DEALER_PORTAL_MUTS_SET_DEALERS = "muts_dealer_portal_set_dealers";
export const API_DEALER_PORTAL_MUTS_SET_DEALER_LOCATIONS = "muts_dealer_portal_set_dealer_locations";
export const API_DEALER_PORTAL_MUTS_SET_DEALER_SUB_COMMON_OWNERS = "muts_dealer_portal_set_dealer_sub_common_owners";
export const API_DEALER_PORTAL_MUTS_SET_LOCATIONS = "muts_dealer_portal_set_locations";

const state = {
    statistic: {
        "tireManagmentReport": {
            "tireManagmentUnits": "",
            "tireManagmentSurveys": "",
            "tireManagmentTires": "",
            "tireManagmentExceptions": ""
        },
        "serviceReport": {
            "serviceEvents": "",
            "serviceETA": "",
            "serviceEmergRoll": "",
            "serviceSmartTech": ""
        },
        "expAppReport": {
            "expeditedApprovalsNeedingConfirmation": "",
            "expeditedApprovalsConfirmed": "",
            "expeditedApprovalsApprovalRate": "",
            "expeditedApprovalsConfirmationPeriod": ""
        },
        "permissions": []
    },
    dealers: [],
    dealerLocations: [],
    locations: []
}

const getters = { 
    getDealerPortalStatistic() {
        return state.statistic
    },
    getDealers() {
        return state.dealers
    },
    getDealerLocations() {
        return state.dealerLocations
    },
    getLocations() {
        return state.locations
    }
};

const actions = {
    [API_DEALER_PORTAL_STATISTIC_GET](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("dealer_portal/overview", params, context)
                .then(({ data }) => {
                    context.commit(API_DEALER_PORTAL_MUTS_SET_STATISTIC, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_DEALER_PORTAL_DEALERS_GET](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("dealer_portal/get_dealers", context)
                .then(({ data }) => {
                    context.commit(API_DEALER_PORTAL_MUTS_SET_DEALERS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_DEALER_PORTAL_LOCATIONS_GET](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("dealer_portal/get_dealer_ctsc_locations", context)
                .then(({ data }) => {
                    context.commit(API_DEALER_PORTAL_MUTS_SET_LOCATIONS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_DEALER_PORTAL_DEALER_LOCATIONS_GET](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("dealer_portal/get_dealer_locations", params, context)
                .then(({ data }) => {
                    context.commit(API_DEALER_PORTAL_MUTS_SET_DEALER_LOCATIONS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }
};

const mutations = {
    [API_DEALER_PORTAL_MUTS_SET_STATISTIC](state, {data}) {
        if (data.dealers.permissions.includes('TMLOGIN')) {
            state.statistic.tireManagmentReport = data.dealers.tireManagmentReport;
        }
        if (data.dealers.permissions.includes('WOLOGIN')) {
            state.statistic.expAppReport = data.dealers.expAppReport;
        }

        if (data.dealers.permissions.includes('SPLOGIN')) {
            state.statistic.serviceReport = data.dealers.serviceReport;
        }

        state.statistic.permissions = [];
        data.dealers.permissions.forEach(permission => {
            state.statistic.permissions.push(permission.trim());
        });
    },
    [API_DEALER_PORTAL_MUTS_SET_DEALERS](state, {data}) {
        state.dealers = data.dealers;
        if(state.dealers.length && state.dealers[0].value == "all")
            state.dealers.splice(0, 1);
    },
    [API_DEALER_PORTAL_MUTS_SET_LOCATIONS](state, {data}) {
        state.locations = data.dealers_ctsc;
    },
    [API_DEALER_PORTAL_MUTS_SET_DEALER_LOCATIONS](state, {data}) {
        state.dealerLocations = data.dealers_locations;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};