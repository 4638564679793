import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import store from "../store";

// action types
export const API_BUSINESS_SOLUTIONS_STATISTIC_GET = "action_business_solutions_statistic_get";

// muts
export const API_BUSINESS_SOLUTIONS_MUTS_SET_STATISTIC = "muts_business_solutions_set_statistic";

const state = {
    statistic: {
        "service_events_count": 0,
        "finished_retreads_count": 0,
        "approved_work_orders_count": 0,
        "all_tires_count": 0,
        "check_point_tires_count": 0,
        "tire_optix_tires_count": 0
    }
}

const getters = { 
    getBusinessSolutionsStatistic() {
        return state.statistic
    }
};

const actions = {
    [API_BUSINESS_SOLUTIONS_STATISTIC_GET](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("business_solutions/get_home_page_statistics", context)
                .then(({ data }) => {
                    context.commit(API_BUSINESS_SOLUTIONS_MUTS_SET_STATISTIC, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }
};

const mutations = {
    [API_BUSINESS_SOLUTIONS_MUTS_SET_STATISTIC](state, {data}) {
        state.statistic = data.business_solutions_home_page_statistics;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};