import ApiService from "@/core/services/api.service";
import { getAccess } from "@/core/services/user.group.access.service";

// action types
export const API_SECURITY_ACTION_SECURITY_LOAD = "action_admin_security_security_load";
export const API_SECURITY_ACTION_SITES_LOAD = "action_admin_security_sites_load";
export const API_SECURITY_ACTION_SECURITY_SEARCH = "action_admin_security_security_search";
export const API_SECURITY_ACTION_SECURITY_ADD = "action_admin_security_security_add";
export const API_SECURITY_ACTION_SECURITY_EDIT = "action_admin_security_security_edit";

// muts
export const API_SECURITY_MUTS_SET_PAGE_PARAMS = "muts_admin_security_set_page_params";
export const API_SECURITY_MUTS_SET_SITES = "muts_admin_security_set_sites";
export const API_SECURITY_MUTS_SET_STATUS = "muts_admin_security_set_status";


const state = {
    page_params: {
        securities: [
            { description: '', explanation: '', security_id: '', site_id: ''}
        ],
        page_number: null,
        page_size: null,
        total_items: null
    },
    sites: [],
    status: {
        status: '',
        description: ''
    }
}

const getters = {
    getAdminSecurityPageParams() {
        return state.page_params;
    },
    getAdminSecuritySites() {
        return state.sites;
    },
    getAdminSecurityStatus() {
        return state.status;
    }
}

const actions = {
    [API_SECURITY_ACTION_SECURITY_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/security/get", params, context)
                    .then(({ data }) => {
                        // console.log('data in API_SECURITY_ACTION_SECURITY_LOAD');
                        // console.log(data);
                        context.commit(API_SECURITY_MUTS_SET_PAGE_PARAMS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_SECURITY_ACTION_SECURITY_ADD](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/security/add", params, context)
                    .then(({ data }) => {
                        // console.log('data in API_SECURITY_ACTION_SECURITY_ADD');
                        // console.log(data);
                        context.commit(API_SECURITY_MUTS_SET_STATUS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_SECURITY_ACTION_SECURITY_EDIT](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/security/edit", params, context)
                    .then(({ data }) => {
                        // console.log('data in API_SECURITY_ACTION_SECURITY_EDIT');
                        // console.log(data);
                        context.commit(API_SECURITY_MUTS_SET_STATUS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_SECURITY_ACTION_SITES_LOAD](context) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.get("admin/security/get_sites", context)
                    .then(({ data }) => {
                        // console.log('data in API_SECURITY_ACTION_SITES_LOAD');
                        // console.log(data);
                        context.commit(API_SECURITY_MUTS_SET_SITES, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_SECURITY_ACTION_SECURITY_SEARCH](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/security/search", params, context)
                    .then(({ data }) => {
                        // console.log('data in API_SECURITY_ACTION_SECURITY_SEARCH');
                        // console.log(data);
                        context.commit(API_SECURITY_MUTS_SET_PAGE_PARAMS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

}

const mutations = {
    [API_SECURITY_MUTS_SET_PAGE_PARAMS](state, {data}) {
        // console.log('data in API_SECURITY_MUTS_SET_PAGE_PARAMS');
        // console.log(data);
        state.page_params.securities = data.securities;
        state.page_params.page_number = data.page_number;
        state.page_params.page_size = data.page_size;
        state.page_params.total_items = data.total_items;
        // console.log(state.page_params.securities);
    },
    [API_SECURITY_MUTS_SET_SITES](state, {data}) {
        // console.log('data in API_SECURITY_MUTS_SET_SITES');
        // console.log(data);
        state.sites = data.sites;
        // console.log('sites : ');
        // console.log(state.sites);
    },
    [API_SECURITY_MUTS_SET_STATUS](state, {data}) {
        // console.log('data in API_SECURITY_MUTS_SET_STATUS');
        // console.log(data);
        state.status = data.status;
        // console.log('status : ');
        // console.log(state.status);
    },

}

export default {
    state,
    actions,
    mutations,
    getters
};