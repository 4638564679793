import ApiService from "@/core/services/api.service";

// action types
export const USER_PROFILE_ACTION_GET_USER = "action_user_profile_get_user";
export const USER_PROFILE_ACTION_EDIT_USER = "action_user_profile_edit_user";

// muts
export const USER_PROFILE_MUTS_SET_USER_INFO = "muts_user_profile_set_user_info";
export const USER_PROFILE_MUTS_SET_USER = "muts_user_profile_set_user";

const state = {
    info: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        ext_phone_number: '',
        fax_number: '',
        unit_of_measurement: 1,
    },
    user: {
        login: null,
        auth_type: null,
        user_name: null,
        user_last_name: null,
        group: null,
        gy_token: null,
        agree_to_fhq_terms: null
    }
}

const getters = {
    getUserProfileInfo() {
        return state.info;
    },
    getUserProfileUser() {
        return state.user;
    }
}

const actions = {
    [USER_PROFILE_ACTION_GET_USER](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("users/get_user", context)
                .then(({ data }) => {
                    context.commit(USER_PROFILE_MUTS_SET_USER_INFO, {data});
                    context.commit(USER_PROFILE_MUTS_SET_USER, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [USER_PROFILE_ACTION_EDIT_USER](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put("users/edit_user", params, context)
                .then(({ data }) => {
                    context.commit(USER_PROFILE_MUTS_SET_USER_INFO, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}

const mutations = {
    [USER_PROFILE_MUTS_SET_USER_INFO](state, {data}) {
        if (data && data.info){
            state.info = data.info;
        }
    },
    [USER_PROFILE_MUTS_SET_USER](state, {data}) {
        state.user = data.user;
    }
}


export default {
    state,
    actions,
    mutations,
    getters
};