import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import { APP_SET_USER, APP_SET_HEADER_MENU, APP_SET_FOOTER_MENU, APP_SET_LANGUAGE_PACK } from "@/core/services/app.state.module";
import '@/scripts/ieEvent.module.js';
/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
    isCheckPermissionSent: false,
    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = (process.env.VUE_APP_API_SERVER);
    },

    /**
     * Set the default HTTP request headers
     */
    setHeader() {
        //Vue.axios.defaults.withCredentials = true;
        if (JwtService.getToken()) {
            Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
        }
        else {
            delete Vue.axios.defaults.headers.common["Authorization"];
        }
        if (JwtService.getApiKey()) {
            Vue.axios.defaults.headers.common["api_key"] = JwtService.getApiKey();
        }
        else {
            delete Vue.axios.defaults.headers.common["api_key"];
        }
    },

    query(resource, params) {
        return Vue.axios.get(resource, params).catch(error => {
            throw new Error(`ApiService ${error}`);
        });
    },

    /**
     * Send the GET HTTP request
     */
    get(resource, context) {
        var call = function (resolve, reject) {
            ApiService.setHeader();
            Vue.axios.get(`${resource}`).
                then(({ data }) => {
                    ApiService.baseParseRequest(data, this.context);
                    return resolve({ data });
                }).
                catch(({ response }) => {
                    const catchErrors = ApiService.catchErrors.bind({context})
                    catchErrors(response);
                    return reject({ response });
                });
        };
        return new Promise(call.bind({ context }));
    },

    /**
     * Set the POST HTTP request
     */
    post(resource, params, context, cancelTokenSource) {
        var call = function (resolve, reject) {
            var meta = {
                cancelToken: cancelTokenSource ? cancelTokenSource.token : null,
                headers: {
                    'User-Language': window.localStorage.getItem("USER_LANGUAGE")
                }
            }
            // console.log(cancelTokenSource);
            // console.log(meta);
            ApiService.setHeader();
            Vue.axios.post(`${resource}`, params, meta).
                then(({ data }) => {
                    ApiService.baseParseRequest(data, this.context);
                    return resolve({ data });
                }).
                catch(({ response }) => {
                    const catchErrors = ApiService.catchErrors.bind({context})
                    catchErrors(response);
                    return reject({ response });
                });

        }

        return new Promise(call.bind({ context }));
    },
    /**
     * Set the POST HTTP request
     */
    postBlob(resource, params, context) {

        var call = function (resolve, reject) {
            ApiService.setHeader();
            Vue.axios.post(`${resource}`, params, { responseType: 'blob' }).
                then(({ data }) => {
                    ApiService.baseParseRequest(data, this.context);
                    return resolve({ data });
                }).
                catch(({ response }) => {
                    const catchErrors = ApiService.catchErrors.bind({context})
                    catchErrors(response);
                    return reject({ response });
                });
        };

        return new Promise(call.bind({ context }));
    },

    /**
     * Send the UPDATE HTTP request
     */
    update(resource, slug, params) {
        ApiService.setHeader();
        return Vue.axios.put(`${resource}/${slug}`, params);
    },

    /**
     * Send the PUT HTTP request
     */
    put(resource, params, context) {
        var call = function (resolve, reject) {
            ApiService.setHeader();
            Vue.axios.put(`${resource}`, params).
                then(({ data }) => {
                    ApiService.baseParseRequest(data, this.context);
                    return resolve({ data });
                }).
                catch(({ response }) => {
                    const catchErrors = ApiService.catchErrors.bind({context})
                    catchErrors(response);
                    return reject({ response });
                });
        };

        return new Promise(call.bind({ context }));
        //return Vue.axios.put(`${resource}`, params);
    },

    /**
     * Send the DELETE HTTP request
     */
    delete(resource, context) {
        var call = function (resolve, reject) {
            ApiService.setHeader();
            Vue.axios.delete(`${resource}`).
                then(({ data }) => {
                    ApiService.baseParseRequest(data, this.context);
                    return resolve({ data });
                }).
                catch(({ response }) => {
                    const catchErrors = ApiService.catchErrors.bind({context})
                    catchErrors(response);
                    return reject({ response });
                });
        };

        return new Promise(call.bind({ context }));
    },
    
    baseParseRequest(jsonResponce, context) {
        if (jsonResponce != undefined) 
        {
            for (var paramName in jsonResponce) {
                if (paramName == "user") {
                    ApiService.baseParseUserResponce(jsonResponce.user, context);
                }
                if (paramName == "app_setup") {
                    ApiService.baseParseAppResponce(jsonResponce.app_setup, context);
                }
              
            }
        }
    },
    baseParseUserResponce(userObject, context) {
        var params = {
            login: null, 
            user_name: null, 
            user_last_name: null, 
            group: null, 
            gy_token: null, 
            api_key: null, 
            user_token_expire_time: null,
            api_key_refresh_time: null, 
            token: null, 
            agree_to_fhq_terms: null,
            user_access_securities: null,
            email: null,
            gmc_token: null,
            auth_type: null
        }

        if (Object.prototype.hasOwnProperty.call(userObject, 'login')) {
            params.login = userObject.login;
        }
        if (Object.prototype.hasOwnProperty.call(userObject, 'user_name')) {
            params.user_name = userObject.user_name;
        }
        if (Object.prototype.hasOwnProperty.call(userObject, 'user_last_name')) {
            params.user_last_name = userObject.user_last_name;
        }
        if (Object.prototype.hasOwnProperty.call(userObject, 'user_access_securities')) {
            params.user_access_securities = userObject.user_access_securities;
        }
        if (Object.prototype.hasOwnProperty.call(userObject, 'group')) {
            params.group = userObject.group;
        }
        if (Object.prototype.hasOwnProperty.call(userObject, 'gy_token')) {
            params.gy_token = userObject.gy_token;
        }
        if (Object.prototype.hasOwnProperty.call(userObject, 'token')) {
            params.token = userObject.token;
        }
        if (Object.prototype.hasOwnProperty.call(userObject, 'api_key')) {
            params.api_key = userObject.api_key;
        }
        if (Object.prototype.hasOwnProperty.call(userObject, 'auth_type')) {
            params.auth_type = userObject.auth_type;
        }
        if (Object.prototype.hasOwnProperty.call(userObject, 'user_token_expire_time')) {
            params.user_token_expire_time = userObject.user_token_expire_time;
            if (userObject.user_token_expire_time != 0) {
                window._session_timeout_time = new Date((new Date().getTime() + params.user_token_expire_time));
            } else {
                window._session_timeout_time = null;
            }
        }
        if (Object.prototype.hasOwnProperty.call(userObject, 'api_key_refresh_time')) {
            params.api_key_refresh_time = userObject.api_key_refresh_time;
            
        }
        if (Object.prototype.hasOwnProperty.call(userObject, 'agree_to_fhq_terms')) {
            params.agree_to_fhq_terms = userObject.agree_to_fhq_terms;
        }
        if (Object.prototype.hasOwnProperty.call(userObject, 'email')) {
            params.email = userObject.email;
        }
        if (Object.prototype.hasOwnProperty.call(userObject, 'gmc_token')) {
            params.gmc_token = userObject.gmc_token;
        }
        context.commit(APP_SET_USER, params);
    },
    baseParseAppResponce(jsonObject, context) {
        if (Object.prototype.hasOwnProperty.call(jsonObject, 'header_content') && jsonObject.header_content != null) {
            context.commit(APP_SET_HEADER_MENU, jsonObject.header_content);
        }
        if (Object.prototype.hasOwnProperty.call(jsonObject, 'footer_content') && jsonObject.footer_content != null) {
            context.commit(APP_SET_FOOTER_MENU, jsonObject.footer_content);
        }
        if (Object.prototype.hasOwnProperty.call(jsonObject, 'language_pack') && jsonObject.language_pack != null) {
            context.commit(APP_SET_LANGUAGE_PACK, jsonObject.language_pack);
        }
    },
    catchErrors(response) {
        if (response == undefined) return;
        if (response.status == 403) {
            const showSessionTimeoutPopupEvent = new window.CustomEvent('showSessionTimeoutPopupEvent')
            document.dispatchEvent(showSessionTimeoutPopupEvent);
            return;
        }
        if(response.status == 401) {
            const showPermissionsPopupEvent = new window.CustomEvent('showPermissionsPopupEvent');
            document.dispatchEvent(showPermissionsPopupEvent);
            if(Object.prototype.hasOwnProperty.call(response, 'data')){
                ApiService.baseParseRequest(response.data, this.context);
            }
            return;
        }
    }
};

export default ApiService;
