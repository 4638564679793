import ApiService from "@/core/services/api.service";

// action types
export const API_FLEET_CENTRAL_ACTION_NA_BOXES_LOAD = "action_fleet_central_na_boxes_load";
export const API_FLEET_CENTRAL_ACTION_CANADIAN_CO_BOXES_LOAD = "action_fleet_central_canadian_co_boxes_load";
export const API_FLEET_CENTRAL_ACTION_CANADIAN_BOXES_LOAD = "action_fleet_central_canadian_boxes_load";
export const API_FLEET_CENTRAL_ACTION_PREFER_FLEETS_BOXES_LOAD = "action_fleet_central_prefer_fleets_boxes_load";
export const API_FLEET_CENTRAL_ACTION_DEFAULT_BOXES_LOAD = "action_fleet_central_default_boxes_load";

// muts
export const API_FLEET_CENTRAL_MUTS_SET_BOXES = "muts_fleet_central_set_boxes";

const state = {
    customer_boxes: {
        expedited_approvals_wo_need_approval: null,
        expedited_approvals_wo_approved: null,
        expedited_approvals_rate: null,
        tire_manag_vehicles_surveyed: null,
        tire_manag_tire_surveys: null,
        tire_manag_tires_surveyed: null,
        retreads_finished: null,
        retreads_wo_in_progress: null,
        retreads_in_inventory: null,
        service_active_calls: null,
        service_emergency_roll_time: null,
        service_mtd_calls: null
    },
    account_name: null
}

const getters = {
    getFleetCentralBoxes() {
        return state.customer_boxes;
    },
    getFleetCentralAccountName() {
        return state.account_name;
    }
}

const actions = {
    [API_FLEET_CENTRAL_ACTION_NA_BOXES_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("fleet_central/get_customer_boxes_by_na/" + params, context)
                .then(({ data }) => {
                    context.commit(API_FLEET_CENTRAL_MUTS_SET_BOXES, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FLEET_CENTRAL_ACTION_CANADIAN_CO_BOXES_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("fleet_central/get_customer_boxes_ca_common_owners/" + params, context)
                .then(({ data }) => {
                    context.commit(API_FLEET_CENTRAL_MUTS_SET_BOXES, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FLEET_CENTRAL_ACTION_CANADIAN_BOXES_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("fleet_central/get_customer_boxes_by_canada/" + params, context)
                .then(({ data }) => {
                    context.commit(API_FLEET_CENTRAL_MUTS_SET_BOXES, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FLEET_CENTRAL_ACTION_DEFAULT_BOXES_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("fleet_central/get_default_boxes/" + params, context)
                .then(({ data }) => {
                    context.commit(API_FLEET_CENTRAL_MUTS_SET_BOXES, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FLEET_CENTRAL_ACTION_PREFER_FLEETS_BOXES_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_central/get_customer_boxes_by_prefer_fleets", params, context)
                .then(({ data }) => {
                    context.commit(API_FLEET_CENTRAL_MUTS_SET_BOXES, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}

const mutations = {
    [API_FLEET_CENTRAL_MUTS_SET_BOXES](state, {data}) {
        state.customer_boxes = data.customer_boxes;
        state.account_name = data.account_name;
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};