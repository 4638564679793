import ApiGmcService from "@/core/services/api.gmc.service";
import { getAccess } from "@/core/services/user.group.access.service";
import Vue from "vue";
import axios from "axios";

// actions
export const API_GMC_GET_EXTERNALLY_MANAGED_USERS_LIST = "action_gmc_get_externally_managed_users_list";
export const API_GMC_GET_EXTERNALLY_MANAGED_USER = "action_gmc_get_externally_managed_user";
export const API_GMC_GET_USER_ROLE = "action_gmc_get_user_role";
export const API_GMC_GET_USER_ASSIGNMENTS = "action_gmc_get_user_assignments";

export const API_GMC_ADD_EXTERNALLY_MANAGED_USER = "action_gmc_add_externally_managed_user";
export const API_GMC_UPDATE_EXTERNALLY_MANAGED_USER = "action_gmc_update_externally_managed_user";
export const API_GMC_DELETE_EXTERNALLY_MANAGED_USER = "action_gmc_delete_externally_managed_user";
export const API_GMC_UPDATE_USER_ASSIGNMENTS = "action_gmc_update_user_assignments";
export const API_GMC_UPDATE_USER_ROLE = "action_gmc_update_user_role";

//muts
export const API_GMC_SET_EXTERNALLY_MANAGED_USERS_LIST = "muts_gmc_externally_managed_users_list";
export const API_GMC_SET_EXTERNALLY_MANAGED_USER = "muts_gmc_externally_managed_user";
export const API_GMC_SET_USER_ROLE = "muts_gmc_user_role";
export const API_GMC_SET_USER_ASSIGNMENTS = "muts_gmc_user_assignments";
export const API_GMC_SET_ERROR = "muts_gmc_error";

const state = {
    externallyManagedUsersList: [
        {
            id: "",
            webId: "",
            email: "",
            role: "",
            firstName: "",
            lastName: "",
            status: ""
        }
    ],
    externallyManagedUser: {
        id: "",
        webId: "",
        email: "",
        role: "",
        firstName: "",
        lastName: "",
        status: ""
    },
    role: "",
    assignments:[
        {
            type: "",
            id: "",
        },
    ],
    last_error: ""
};

const getters = {
    getGmcExternallyManagedUsersList() {
        return state.externallyManagedUsersList
    },
    getGmcExternallyManagedUser() {
        return state.externallyManagedUser
    },
    getGmcUserRole() {
        return state.role
    },
    getGmcUserAssignments() {
        return state.assignments
    },
    getGmcLastError() {
        return state.last_error
    }
};

const actions = {
    [API_GMC_GET_EXTERNALLY_MANAGED_USERS_LIST](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiGmcService.get("/externally-managed-users" + params, context)
                    .then(({ data }) => {
                        context.commit(API_GMC_SET_EXTERNALLY_MANAGED_USERS_LIST, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_GMC_SET_ERROR, response);
                        reject(response);
                    });
            }
            else {
                reject();
            }
        });
    },
    [API_GMC_GET_EXTERNALLY_MANAGED_USER](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiGmcService.get("/externally-managed-users/" + encodeURIComponent(params.toLowerCase()), context)
                    .then(({ data }) => {
                        context.commit(API_GMC_SET_EXTERNALLY_MANAGED_USER, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_GMC_SET_ERROR, response);
                        reject(response);
                    });
            }
            else {
                reject();
            }
        });
    },
    [API_GMC_GET_USER_ROLE](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiGmcService.get("/externally-managed-users/" + params + "/role", context)
                    .then(({ data }) => {
                        context.commit(API_GMC_SET_USER_ROLE, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_GMC_SET_ERROR, response);
                        reject(response);
                    });
            }
            else {
                reject();
            }
        });
    },
    [API_GMC_GET_USER_ASSIGNMENTS](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiGmcService.get("/externally-managed-users/" + params + "/fleet-assignments", context)
                    .then(({ data }) => {
                        context.commit(API_GMC_SET_USER_ASSIGNMENTS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_GMC_SET_ERROR, response);
                        reject(response);
                    });
            }
            else {
                reject();
            }
        });
    },

    [API_GMC_ADD_EXTERNALLY_MANAGED_USER](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiGmcService.post("/externally-managed-users", params, context)
                    .then(({ data }) => {
                        context.commit(API_GMC_SET_EXTERNALLY_MANAGED_USER, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_GMC_SET_ERROR, response);
                        reject(response);
                    });
            }
            else {
                reject();
            }
        });
    },
    [API_GMC_UPDATE_EXTERNALLY_MANAGED_USER](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiGmcService.update("/externally-managed-users", params.slug, params.params, context)
                    .then(({ data }) => {
                        context.commit(API_GMC_SET_EXTERNALLY_MANAGED_USER, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_GMC_SET_ERROR, response);
                        reject(response);
                    });
            }
            else {
                reject();
            }
        });
    },
    [API_GMC_DELETE_EXTERNALLY_MANAGED_USER](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiGmcService.delete("/externally-managed-users/" + encodeURIComponent(params.toLowerCase()), context)
                    .then(() => {
                        context.commit(API_GMC_SET_EXTERNALLY_MANAGED_USER, {});
                        resolve();
                    })
                    .catch(({ response }) => {
                        context.commit(API_GMC_SET_ERROR, response);
                        reject(response);
                    });
            }
            else {
                reject();
            }
        });
    },

    [API_GMC_UPDATE_USER_ASSIGNMENTS](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiGmcService.put("/externally-managed-users/" + params.id + "/fleet-assignments", params.params, context)
                    .then(({ data }) => {
                        context.commit(API_GMC_SET_USER_ASSIGNMENTS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_GMC_SET_ERROR, response);
                        reject(response);
                    });
            }
            else {
                reject();
            }
        });
    },
    [API_GMC_UPDATE_USER_ROLE](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiGmcService.put("/externally-managed-users/" + params.id + "/role", params.params, context)
                    .then(({ data }) => {
                        context.commit(API_GMC_SET_USER_ROLE, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_GMC_SET_ERROR, response);
                        reject(response);
                    });
            }
            else {
                reject();
            }
        });
    },
};

const mutations = {    
    [API_GMC_SET_EXTERNALLY_MANAGED_USERS_LIST](state, data) {
        state.externallyManagedUsersList = data;
    },
    [API_GMC_SET_EXTERNALLY_MANAGED_USER](state, data) {
        state.externallyManagedUser = data;
    },
    [API_GMC_SET_USER_ROLE](state, data) {
        state.role = data;
    },
    [API_GMC_SET_USER_ASSIGNMENTS](state, data) {
        state.assignments = data;
    },
    [API_GMC_SET_ERROR](state, detail) {
        state.last_error = detail;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};