<template>
    <div>
        <popup
            v-if="modal.show"
            :title="modal.title"
            :message="modal.message"
            :isError="modal.isError"
            @close="modal.show=false"
            :isClosable="modal.isClosable"
        >
            <template v-if="modal.leftBtn || modal.rightBtn" v-slot:buttons>

                <button 
                    v-if="modal.leftBtn" 
                    type="button" 
                    class="btn btn-secondary" 
                    @click="modal.leftBtn.callback"
                >
                    {{ $trans(modal.leftBtn.label) }}
                </button>

                <button 
                    v-if="modal.rightBtn" 
                    type="button" 
                    class="btn btn-primary" 
                    @click="modal.rightBtn.callback"
                >
                    {{ $trans(modal.rightBtn.label) }}
                </button>

            </template>

        </popup>    
    </div>    
</template>
<script>
import Popup from '@/components/Popup.vue';
import EmitModal from '@/classes/EmitModal.js';

export default {
    data(){
        return {  
            modal: {
                show: false,
            },
        }
    },
    methods: {
        showModal: function(modal) {
            this.modal = new EmitModal(modal.title, modal.message, modal.isError, modal.isClosable);

            if(modal.leftBtn) {
                this.modal.setLeftBtn(modal.leftBtn.callback, modal.leftBtn.label);
            }
            if(modal.rightBtn) {
                this.modal.setRightBtn(modal.rightBtn.callback, modal.rightBtn.label);
            }
            
            this.modal.show = true;
        },
        closeModal: function() {
            if(this.modal) {
                this.modal.show = false;
            }
        }
         
    },
    created() {

        this.$root.$on('show-popup', modal => {
            this.showModal(modal);
        });
        this.$root.$on('close-popup', () => {this.closeModal(); });
    },

    beforeDestroy() {
        this.$root.$off('show-popup');
        this.$root.$off('close-popup');
    },
    components: {
        'popup': Popup
    }
}
</script>