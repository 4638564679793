<template>
    <div id="app">
        <div class="content">
            <header-menu></header-menu>
            <div v-if="isIE" class ="ie-banner">{{$trans('We have detected that you may be using an outdated browser that is not compatible with our website.\n For a better browsing experience, please view using Microsoft Edge, Google Chrome, Mozilla Firefox or Safari.')}}</div>       
            <router-view :key="$route.path"></router-view>      
        </div>
        <footer-menu></footer-menu>
        <EmitModal ref="AppEmitModal"></EmitModal>
        <Loader ref="AppLoader"></Loader>
        <Notify @close="$root.$emit('close-notify')"></Notify>
        <TermsPopup ref="AppTermsPopup" v-if="isAgreeWithFHQTerms" @close="isAgreeWithFHQTerms=$event"></TermsPopup> 
        <Popup v-if="showPermissionsPopup"
            title="Error"
            message="You don't have permissions to this page!"
            :isError="true"
            @close="closePermissionsPopup"
            >
            <template #buttons="buttonsProps">
                <button class="btn btn-primary btn-lg" @click="buttonsProps.close">{{ $trans("Close") }}</button>
            </template>
        </Popup>
        <Popup v-if="showPermissionsToActionPopup"
            title="Error"
            message="You don't have permissions to this action!"
            :isError="true"
            @close="closePermissionsToActionsPopup"
            >
            <template #buttons="buttonsProps">
                <button class="btn btn-primary btn-lg" @click="buttonsProps.close">{{ $trans("Close") }}</button>
            </template>
        </Popup>
        <Popup v-if="showSessionTimeoutPopup"
            title="Session expired"
            message="Your session has expired due to inactivity. Please log in again."
            :isOutsideClickable="true"
            @close="closeSessionTimeoutPopup"
            >
            <template v-slot:buttons="buttonsProps">
                <button type="button" class="btn btn-primary btn-lg" @click="buttonsProps.close">{{ $trans("OK") }}</button>
            </template>
        </Popup>
    </div>
</template>

<script>
    import Loader from "@/components/Loader.vue";
    import TermsPopup from "@/components/TermsPopup.vue";
    import Notify from "@/components/Notify.vue";
    import HeaderMenu from "@/view/menu/HeaderMenu.vue";
    import FooterMenu from "@/view/menu/FooterMenu.vue";
    import Popup from './components/Popup.vue';
    import EmitModal from './components/EmitModal.vue';
    import { mapGetters, mapActions } from "vuex";
    import { API_VERIFY_AUTH, API_LANGUAGE_CHANGED, API_CHECK_PERMISSIONS } from "@/core/services/api/auth.module";
    import { APP_SET_AUTHENTICATED, APP_RESET_INTERFACE } from "@/core/services/app.state.module";
 
    export default {
        name: 'app',
        data: function () {
            return {
                // refreshTokenInterval: null,
                callCheckPermissionsInterval: null,
                transitionName: 'slide-left',
                showTermsPopup: false,
                showPermissionsPopup: false,
                showPermissionsToActionPopup: false,
                showSessionTimeoutPopup: false,
                permissionMessage: ''
            }
        },
        methods: {
            refreshTokenTimer() {
                var now = new Date().getTime();
                //console.log(window._session_timeout_time < now);
                if (location.pathname == '/membership/login' || !window._session_timeout_time) {
                    return;
                }
                if (this.IsAuthorized && window._session_timeout_time != null && window._session_timeout_time < now) {
                    this.showSessionTimeoutPopup = true;
                    // this.$store.dispatch(API_VERIFY_AUTH)
                    // .catch(() => {
                        this.$store.dispatch(APP_RESET_INTERFACE).catch(() => {});
                        this.showSessionTimeoutPopup = true;
                    // })
                }  
            },
            callCheckPermissions() {
                if(this.User.ApiKeyRefreshTime > 0) {
                    this.$store.dispatch(API_CHECK_PERMISSIONS, { path: location.pathname } );
                }
            },
            closePermissionsPopup() {
                this.showPermissionsPopup = false;
                this.$router.push({ name: "Index"});
            },
            closePermissionsToActionsPopup() {
                this.showPermissionsToActionPopup = false;
            },
            closeSessionTimeoutPopup() {
                this.showSessionTimeoutPopup = false;
                this.$router.push({ name: "Login", query: { returnUrl: location.pathname + location.search } });
            }
        },
        mounted() {
            // this.$nextTick(function () {
            //     this.$store.dispatch(API_LANGUAGE_CHANGED)
            //     .catch((e)=>{
            //         console.log(e);
            //     });
            // })
            this.refreshTokenInterval = window.setInterval(this.refreshTokenTimer, 15 * 1000);
        },
        computed: {
			//console: () => console,
			...mapGetters({
                IsAuthorized: "AppIsAuthenticated",
                User: "AppGetUser"
            }),
            isAgreeWithFHQTerms: {
                get() {
                    // console.log('computed isAgreeWithFHQTerms');
                    // console.log(this.User.AgreeToFhqTerms);
                    if(this.IsAuthorized == true && this.User.AgreeToFhqTerms === false) {
                        // console.log('show please');
                        return true;
                    }
                    return this.showTermsPopup;
                },
                set(newVal) {
                    this.showTermsPopup = newVal;
                }
            },
            isIE() {
                return !!document.documentMode;
            }
        },
        created() {
            //refresh every 45sec token
            
            //this.callCheckPermissionsInterval = window.setTimeout(this.callCheckPermissions, this.User.ApiKeyRefreshTime);

            document.addEventListener('showPermissionsPopupEvent', () => {
                this.showPermissionsPopup = true;
			})

            document.addEventListener('showPermissionsPopupToActionEvent', () => {
				this.showPermissionsToActionPopup = true;
			})
            
            document.addEventListener('showSessionTimeoutPopupEvent', () => {
                if (location.pathname != '/' && location.pathname != '/membership/login' && window._session_timeout_time)
				    this.showSessionTimeoutPopup = true;
			})

            document.addEventListener("touchstart", function() {},false);
        },
        beforeDestroy() {
            window.clearInterval(this.refreshTokenInterval);
            //window.clearTimeout(this.callCheckPermissionsInterval);
        },
        components: { HeaderMenu, FooterMenu, Loader, TermsPopup, Notify, Popup, EmitModal }  
    };
</script>

<style src='bootstrap/dist/css/bootstrap.css'></style>
<style src='bootstrap-vue/dist/bootstrap-vue.css'></style>
<style >
    @import "./assets/styles/fonts.css";
    @import "./assets/styles/common.css"; /*@import "https://portal.goodyeartrucktires.com/theme/0.0.0/styles/common.css";*/
    @import "./assets/styles/universal-menu.css";
    @import "./assets/styles/GYLoginPortal.css";
    @import "./assets/styles/custom.css";
    @import "./assets/styles/app.css";

    .ie-banner {
        text-align: center;
        vertical-align: middle;
        padding: 25px 0;
        height: 100px;
        background-color:  #f2dede; 
        color: #a94442;
        white-space: pre-line
    }
    
</style>

