import ApiService from "@/core/services/api.service";
import {APP_SET_USER} from "@/core/services/app.state.module";
// actions
export const API_ACTION_AGREE_WITH_FHQ_TERMS = "action_agree_with_fhq_terms";
// muts
export const API_MUTS_AGREE_WITH_FHQ_TERMS_SET_STATUS = "muts_agree_with_fhq_terms_set_status"

const state = {
    status: {}
}

const getters = { 
    getAgreeWithFHQTermsStatus() {
        return state.status;
    }
};

const actions = {
    [API_ACTION_AGREE_WITH_FHQ_TERMS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put('users/agree_to_fhq_terms/' + params, params, context)
                .then(({ data }) => {
                    //console.log('in API_ACTION_AGREE_WITH_FHQ_TERMS');
                    //console.log(params);
                    //console.log(data);
                    context.commit(API_MUTS_AGREE_WITH_FHQ_TERMS_SET_STATUS, {data});
                    // context.commit(APP_SET_USER, data.user.login, data.user.user_name, data.user.user_last_name, data.user.group, 
                    //     data.user.gy_token, data.user.token, data.user.agree_to_fhq_terms)
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });  
        })
    },
}

const mutations = {
    [API_MUTS_AGREE_WITH_FHQ_TERMS_SET_STATUS](state, {data}) {
        //console.log('in API_MUTS_AGREE_WITH_FHQ_TERMS_SET_STATUS');
        //console.log(data);
        state.status = data.status;
    }
}

export default {
    state,
    actions,
    mutations,
    getters
};