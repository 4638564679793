import ApiService from "@/core/services/api.service";
import { getAccess } from "@/core/services/user.group.access.service";

// action types
export const API_USER_GROUPS_ACTION_LOAD = "action_admin_user_groups_load";
// export const API_SECURITY_ACTION_SITES_LOAD = "action_admin_security_sites_load";
export const API_USER_GROUPS_ACTION_SEARCH = "action_admin_user_groups_search";
export const API_USER_GROUPS_ACTION_ADD = "action_admin_user_groups_add";

// muts
export const API_USER_GROUPS_MUTS_SET_PAGE_PARAMS = "muts_admin_user_groups_set_page_params";
// export const API_SECURITY_MUTS_SET_SITES = "muts_admin_security_set_sites";
export const API_USER_GROUPS_MUTS_SET_STATUS = "muts_admin_user_groups_set_status";

const state = {
    page_params: {
        user_groups: [
            { user_group: '', description: ''}
        ],
        page_number: null,
        page_size: null,
        total_items: null
    },
    status: {
        status: '',
        description: ''
    }
}

const getters = {
    getAdminUserGroupsPageParams() {
        return state.page_params;
    },
    getAdminUserGroupsStatus() {
        return state.status;
    }
}

const actions = {
    [API_USER_GROUPS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/user_groups/get", params, context)
                    .then(({ data }) => {
                        //console.log('data in API_USER_GROUPS_ACTION_LOAD');
                        //console.log(data);
                        context.commit(API_USER_GROUPS_MUTS_SET_PAGE_PARAMS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_USER_GROUPS_ACTION_ADD](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/user_groups/add", params, context)
                    .then(({ data }) => {
                        //console.log('data in API_USER_GROUPS_ACTION_ADD');
                        //console.log(data);
                        context.commit(API_USER_GROUPS_MUTS_SET_STATUS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_USER_GROUPS_ACTION_SEARCH](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/user_groups/search", params, context)
                    .then(({ data }) => {
                        //console.log('data in API_USER_GROUPS_ACTION_SEARCH');
                        //console.log(data);
                        context.commit(API_USER_GROUPS_MUTS_SET_PAGE_PARAMS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

}

const mutations = {
    [API_USER_GROUPS_MUTS_SET_PAGE_PARAMS](state, {data}) {
        //console.log('data in API_USER_GROUPS_MUTS_SET_PAGE_PARAMS');
        //console.log(data);
        state.page_params.user_groups = data.user_groups;
        state.page_params.page_number = data.page_number;
        state.page_params.page_size = data.page_size;
        state.page_params.total_items = data.total_items;
        //console.log(state.page_params.user_groups);
    },
    [API_USER_GROUPS_MUTS_SET_STATUS](state, {data}) {
        //console.log('data in API_USER_GROUPS_MUTS_SET_STATUS');
        //console.log(data);
        state.status = data.status;
        //console.log('status : ');
        //console.log(state.status);
    },

}

export default {
    state,
    actions,
    mutations,
    getters
};