import ApiService from "@/core/services/api.service";
import { getAccess } from "@/core/services/user.group.access.service";
import { Statuses } from "../../../utils/enums/UserStatusEnum";

// action types add user
export const API_ADMIN_SELECT_HEAD_NONSIG_TABLE = "action_admin_select_head_nonsig_table";
export const API_ADMIN_GET_DEF_SEC = "action_admin_get_def_sec";
export const API_ADMIN_GET_DEF_SEC_BY_ID = "action_admin_get_def_sec_by_id";
export const API_ADMIN_DOES_USER_ID_EXIST = "action_admin_does_user_id_exist";
export const API_ADMIN_IS_ONLY_LEASING_ACCESS = "action_admin_is_only_leasing_access";
export const API_ADMIN_SAVE_USER = "action_admin_save_user";
export const API_ADMIN_GET_FTN_DESCRIPTION = "action_admin_get_ftn_description";
export const API_ADMIN_GET_SELECT_TWO_DATA_BY_SUB_STRING = "action_admin_get_select_two_data_by_sub_string";
export const API_ADMIN_GET_SELECT_LOCATIONS_BY_SUB_STRING = "action_admin_get_select_locations_by_sub_string";
export const API_ADMIN_GET_SELECT_LOCATION_GROUPS_BY_SUB_STRING = "action_admin_get_select_location_groups_by_sub_string";
export const API_ADMIN_GET_SELECT_LOCAL_BOOKS_BY_SUB_STRING = "action_admin_get_select_local_books_by_sub_string";
export const API_ADMIN_GET_SELECT_HEAD_LOCAL_BOOKS_BY_SUB_STRING = "action_admin_get_select_head_local_books_by_sub_string";
export const API_ADMIN_GET_SELECT_LEASINGS_BY_SUB_STRING = "action_admin_get_select_leasings_by_sub_string";
export const API_ADMIN_GET_SELECT_LEASING_LOCATIONS_BY_SUB_STRING = "action_admin_get_select_leasings_locactions_by_sub_string";
export const API_ADMIN_UNLOCK_USER = "action_admin_unlock_user";
export const API_ADMIN_GET_IS_EMAIL_EXISTS = "action_admin_get_is_email_exists";
export const API_ADMIN_CHECK_USER_STATUS = "action_admin_get_check_user_status";
export const API_ADMIN_GET_AUTH_TYPES = "action_admin_get_auth_types"
export const API_ADMIN_GET_API_KEY_INTEGRATION_OPTIONS = "action_api_admin_get_api_key_integration_options"
export const API_ADMIN_GET_GENERATED_API_KEY_INTEGRATION_USER_ID = "action_api_admin_get_generated_api_key_integration_user_id"
export const API_ADMIN_GET_GENERATED_USER_PASSWORD = "action_api_admin_get_generated_user_password"
export const API_ADMIN_GET_CONFLICT_LOCAL_BOOKS = "action_api_admin_get_conflict_local_books"

//in future
export const API_ADMIN_EDIT_USER_LOAD_SEC_GROUPS = "action_admin_edit_user_load_sec_groups";
export const API_ADMIN_EDIT_USER_LOAD_USER_GROUPS = "action_admin_edit_user_load_user_groups";
export const API_ADMIN_EDIT_USER_LOAD = "action_admin_edit_user_load";

// action types search users
export const API_ADMIN_GET_USERS_LIST = "action_admin_users_list";
export const API_ADMIN_USERS_LIST_WITH_SUBSTRING_SORTING = "action_admin_users_list_with_substring_sorting";
export const API_ADMIN_EXPORT_USERS_LIST_TO_EXCEL = "action_admin_export_users_list_to_excel";
export const API_ADMIN_EXPORT_USERS_LIST_TO_PDF = "action_admin_export_users_list_to_pdf";
export const API_ADMIN_EMAIL_USER_INFO = "action_admin_email_user_info";
export const API_ADMIN_RESEND_INVITE = "action_admin_resend_invite";
export const API_ADMIN_EMAIL_TO_GROUP = "action_admin_email_to_group";

export const API_ADMIN_SEARCH_USERS_LOAD = "action_admin_search_users_load";

//muts 
export const API_ADMIN_USER_SET_B2C_STATUS = "muts_admin_users_set_b2c_status";
export const API_ADMIN_USERS_SET_PAGE_PARAMS = "muts_admin_users_set_page";
export const API_ADMIN_USERS_SET_LIST = "muts_admin_users_set_list";
export const API_ADMIN_USERS_ERROR = "muts_admin_users_error";
export const API_ADMIN_SET_USERS_LIST = "action_admin_set_users_list";
export const API_ADMIN_USERS_SET_USER_STATUS = "muts_admin_users_set_user_status";
export const API_ADMIN_USERS_SET_API_KEY_INTEGRATION_OPTIONS = "muts_admin_users_set_api_key_integration_options";

const state = {
    page_params: {
        us_regions: [],
        canada_region: []
    },
    user_list: [],
    last_error: "",
    users_table: {
        items: [],
        page_number: null,
        page_size: null,
        total_items: 0,
    },
    isUserActive: false,
    isB2CUserActive: false,
    apiKeyIntegrationOptions: [],
};

const getters = {
    getIsB2CUserActive() {
        return state.isB2CUserActive 
     },
    getUserStatus() {
       return state.isUserActive 
    },
    getAdminUsersPageParams() {
        return state.page_params
    },
    getAdminUsersList() {
        return state.user_list
    },
    getAdminUsersLastError() {
        return state.last_error
    },
    getAdminUsersTable() {
        return state.users_table;
    },
    getAdminUsersApiKeyIntegrationOptions() {
        return state.apiKeyIntegrationOptions;
    },
};

const actions = {
    [API_ADMIN_EDIT_USER_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.get("admin/admin/get_user/" + params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_USERS_SET_PAGE_PARAMS, data);
                        context.commit(API_ADMIN_USERS_SET_USER_STATUS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_USERS_ERROR, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_EDIT_USER_LOAD_SEC_GROUPS](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/security_groups/get_avaliable_securities", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_USERS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_USERS_ERROR, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_EDIT_USER_LOAD_USER_GROUPS](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/user_groups/get", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_USERS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_USERS_ERROR, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_SELECT_HEAD_NONSIG_TABLE](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/admin/search_office_heads", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_USERS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_USERS_ERROR, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_GET_DEF_SEC](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/security_groups/get", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_USERS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_USERS_ERROR, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_GET_DEF_SEC_BY_ID](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.get("admin/admin/get_default_securities/" + params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_USERS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_USERS_ERROR, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_DOES_USER_ID_EXIST](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.get("admin/admin/does_user_id_exist/" + params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_USERS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_USERS_ERROR, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },


    [API_ADMIN_IS_ONLY_LEASING_ACCESS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("admin/admin/is_only_leasing_access/" + params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(API_ADMIN_USERS_ERROR, response);
                    reject(response);
                });
        });
    },



    [API_ADMIN_UNLOCK_USER](context, web_id) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/admin/unlock_user/"+web_id, {}, context)
                    .then(({ data }) => {
                        //context.commit(API_ADMIN_USERS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        //context.commit(API_ADMIN_USERS_ERROR, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_SAVE_USER](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/admin/save_new_user", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_USERS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_USERS_ERROR, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_GET_FTN_DESCRIPTION](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.get("admin/admin/get_ftn_description/" + params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_USERS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_USERS_ERROR, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },   

    [API_ADMIN_GET_SELECT_TWO_DATA_BY_SUB_STRING](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                var model = {
                    substring: params.subString
                }
                ApiService.post("admin/admin/" + params.type, model, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_USERS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_USERS_ERROR, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    }, 

    [API_ADMIN_GET_SELECT_LOCATIONS_BY_SUB_STRING](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {              
                ApiService.post("admin/admin/" + params.path, params.serachModel, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_USERS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_USERS_ERROR, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    }, 
    [API_ADMIN_GET_SELECT_LOCATION_GROUPS_BY_SUB_STRING](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {              
                ApiService.post("admin/admin/" + params.path, params.serachModel, context)
                    .then(({ data }) => {
                        // context.commit(API_ADMIN_USERS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_USERS_ERROR, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    }, 
    //LocalBook
    [API_ADMIN_GET_SELECT_LOCAL_BOOKS_BY_SUB_STRING](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {  
                ApiService.post("admin/admin/"+ params.path, params.searchModel, context)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_USERS_ERROR, response);
                        reject(response);
                    });
            }
            else {
                reject();
            }
        });
    }, 
    [API_ADMIN_GET_SELECT_HEAD_LOCAL_BOOKS_BY_SUB_STRING](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {  
                ApiService.post("admin/admin/get_head_local_books", params.searchModel, context)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_USERS_ERROR, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_ADMIN_GET_SELECT_LEASINGS_BY_SUB_STRING](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {  
                ApiService.post("admin/admin/get_leasings", params.searchModel, context)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_USERS_ERROR, response);
                        reject(response);
                    });
            }
            else {
                reject();
            }
        });
    }, 
    [API_ADMIN_GET_SELECT_LEASING_LOCATIONS_BY_SUB_STRING](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {  
                ApiService.post("admin/admin/"+ params.path, params.searchModel, context)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_USERS_ERROR, response);
                        reject(response);
                    });
            }
            else {
                reject();
            }
        });
    },
    
//User search
    [API_ADMIN_EMAIL_USER_INFO](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                var model = {
                    substring: params
                }
                ApiService.post("admin/admin/email_user_info/", model, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_USERS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_USERS_ERROR, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_GET_USERS_LIST](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/admin/search_users", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_SET_USERS_LIST, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_USERS_LIST_WITH_SUBSTRING_SORTING](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/admin/search_users_by_substring", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_USERS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_EXPORT_USERS_LIST_TO_EXCEL](context, params) {
        
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.postBlob("admin/admin/export_users_list_to_excel", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_USERS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_USERS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_ADMIN_EXPORT_USERS_LIST_TO_PDF](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.postBlob("admin/admin/export_users_list_to_pdf", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_USERS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_USERS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_ADMIN_GET_IS_EMAIL_EXISTS](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/admin/is_email_exists", params, context)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_USERS_ERROR, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_ADMIN_CHECK_USER_STATUS](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/admin/get_user_status/" + params, {}, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_USER_SET_B2C_STATUS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_USERS_ERROR, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_ADMIN_GET_AUTH_TYPES](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/admin/get_auth_types", params, context)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    
    [API_ADMIN_RESEND_INVITE](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/admin/resend_invite/" + params, {}, context)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });
            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_EMAIL_TO_GROUP](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/admin/email_to_group/" + params, {}, context)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });
            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_GET_API_KEY_INTEGRATION_OPTIONS](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.get("admin/admin/get_api_key_integration_options", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_USERS_SET_API_KEY_INTEGRATION_OPTIONS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_GET_GENERATED_API_KEY_INTEGRATION_USER_ID](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/admin/get_generated_api_key_integration_user_id/?nameToChange=" + params, params, context)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_GET_GENERATED_USER_PASSWORD](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/admin/get_generated_user_password/?stringToEncode=" + params, params, context)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_GET_CONFLICT_LOCAL_BOOKS](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/admin/get_conflict_user_id", params, context)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
};

const mutations = { 
    [API_ADMIN_USERS_SET_USER_STATUS](state, data) {
        if (data.user_edit){
            state.isUserActive =  data.user_edit.status === Statuses.ACTIVE;
        }
    },
    [API_ADMIN_USERS_SET_PAGE_PARAMS](state, data) {
        state.us_regions = data.us_regions;
        state.canada_region = data.canada_region;
    },
    [API_ADMIN_USERS_SET_LIST](state, data) {
        state.user_list = data;
    },
    [API_ADMIN_USERS_ERROR](state, detail) {
        state.last_error = detail;
    },
    [API_ADMIN_USER_SET_B2C_STATUS](state, data) {
        state.isB2CUserActive = data.userStatus;
    },
    [API_ADMIN_SET_USERS_LIST](state, data) {
        state.users_table.items = data.users;
        state.users_table.page_number = data.page_number;
        state.users_table.page_size = data.page_size;
        state.users_table.total_items = data.total_items;
    },
    [API_ADMIN_USERS_SET_API_KEY_INTEGRATION_OPTIONS](state, data) {
        state.apiKeyIntegrationOptions = data;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
