import ApiService from "@/core/services/api.service";

// action types
export const USER_ACTION_NEW_REGISTRATION_SUBMIT = "user_action_new_registration_submit";

// muts 
export const USER_NEW_REGISTRATION_MUTS_SET_STATUS = "user_new_registration_muts_set_status";

const state = {
    status: {
        status: '',
        description: ''
    }
}

const getters = { 
    getNewRegistrationStatus() {
        return state.status;
    }
};

const actions = {
    [USER_ACTION_NEW_REGISTRATION_SUBMIT](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("users/registration", params, context)
                .then(({ data }) => {
                    context.commit(USER_NEW_REGISTRATION_MUTS_SET_STATUS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}

const mutations = {
    [USER_NEW_REGISTRATION_MUTS_SET_STATUS](state, {data}) {
        //console.log(data.status);
        state.status = data.status;
    }
}

export default {
    state,
    actions,
    mutations,
    getters
};
