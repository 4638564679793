import ApiService from "@/core/services/api.service";

// action types
export const API_FLEET_CENTRAL_ACTION_SELECTS_LOAD = "action_fleet_central_selects_load";

// muts
export const API_FLEET_CENTRAL_MUTS_SET_PAGE_PARAMS = "muts_fleet_central_set_page_params";

const state = {
    fleet_central_home_page: {
        fleet_types: [
            {
                value: null,
                text: '',
                is_selected: null
            }
        ],
        us_national_accounts: [
            {
                value: null,
                text: '',
                is_selected: null
            }
        ],
        canadian_national_accounts: [
            {
                value: null,
                text: '',
                is_selected: null
            }
        ],
        preferred_fleets: [
            {
                preferred_fleet_number: null,
                dealer_nonsig_number: '',
                text: null
            }
        ],
        canadian_common_owners: [
            {
                value: null,
                text: '',
                is_selected: null
            }
        ],

        is_multi_account: null,
        is_national_account: null,
        is_canadian_national_account: null,
        is_preferredFleets: null,
        is_canadian_common_owners: null,
    }
}

const getters = {
    getFleetCentralFleetTypes() {
        return state.fleet_central_home_page.fleet_types;
    },
    getFleetCentralUSNationalAccounts() {
        return state.fleet_central_home_page.us_national_accounts;
    },
    getFleetCentralCanadianNationalAccounts() {
        return state.fleet_central_home_page.canadian_national_accounts;
    },
    getFleetCentralPreferredFleets() {
        return state.fleet_central_home_page.preferred_fleets;
    },
    getFleetCentralCanadianCommonOwners() {
        return state.fleet_central_home_page.canadian_common_owners;
    },
    getFleetCentralMultipleParams() {
        return {
            is_multi_account: state.fleet_central_home_page.is_multi_account,
            is_national_account: state.fleet_central_home_page.is_national_account,
            is_canadian_national_account: state.fleet_central_home_page.is_canadian_national_account,
            is_preferredFleets: state.fleet_central_home_page.is_preferredFleets,
            is_canadian_common_owners: state.fleet_central_home_page.is_canadian_common_owners,
            // state.fleet_central_home_page.national_account_number,
            // state.fleet_central_home_page.national_account_name
        }
    }
}

const actions = {
    [API_FLEET_CENTRAL_ACTION_SELECTS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("fleet_central/get_fleet_central_home_page_model", context)
                .then(({ data }) => {
                    context.commit(API_FLEET_CENTRAL_MUTS_SET_PAGE_PARAMS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}

const mutations = {
    [API_FLEET_CENTRAL_MUTS_SET_PAGE_PARAMS](state, {data}) {
        state.fleet_central_home_page = data.fleet_central_home_page;
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};