const ID_TOKEN_KEY = "gy_portal_token";
const ID_API_KEY = "gy_portal_key";
const GMC_TOKEN_KEY = "gmc_token";
const GMC_AUTH_TOKEN_KEY = "gmc_auth_token";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getApiKey = () => {
  return window.localStorage.getItem(ID_API_KEY);
};

export const getGmcToken = () => {
  return window.localStorage.getItem(GMC_TOKEN_KEY);
};

export const getGmcAuthToken = () => {
  return window.localStorage.getItem(GMC_AUTH_TOKEN_KEY);
};

export const saveToken = token => {
  // console.log("apply new token:" + token);
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveApiKey = token => {
  // console.log("apply new api key:" + token); 
  window.localStorage.setItem(ID_API_KEY, token);
};

export const saveGmcToken = token => {
  window.localStorage.setItem(GMC_TOKEN_KEY, token);
};

export const saveGmcAuthToken = token => {
  window.localStorage.setItem(GMC_AUTH_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const destroyApiKey = () => {
  window.localStorage.removeItem(ID_API_KEY);
};

export const destroyGmcToken = () => {
  window.localStorage.removeItem(GMC_TOKEN_KEY);
};

export const destroyGmcAuthToken = () => {
  window.localStorage.removeItem(GMC_AUTH_TOKEN_KEY);
};

export default { 
  getToken, saveToken, destroyToken, 
  getApiKey, saveApiKey, destroyApiKey, 
  getGmcToken, saveGmcToken, destroyGmcToken, 
  getGmcAuthToken, saveGmcAuthToken, destroyGmcAuthToken,
};
