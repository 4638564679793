const ID_LANG_KEY = "USER_LANGUAGE";
const ID_LANG_LIST_KEY = "LANGUAGES";
const ID_LANG_EXP_KEY = "LANGUAGES_EXP";

import VueI18n from 'vue-i18n';
import { API_TRANSLATIONS_ACTION_LOAD } from "@/core/services/api/translations.module";
import { API_START_APP } from "@/core/services/api/auth.module";
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

export const initI18n = (store) => {
    var language = window.localStorage.getItem(ID_LANG_KEY);
    if (!language)
    {
        /*if (window.navigator.languages) {
            language = window.navigator.languages[0];
        } 
        else {
            language = window.navigator.userLanguage || window.navigator.language;
        }*/
        language = "en-US";
        window.localStorage.setItem(ID_LANG_KEY, language);
    }    

    return new Promise((resolve, reject) => {
        getLocalesList(store).then(function(){
            callLang(store).then(function(i18n){
                return resolve(i18n);
            })
        }).catch(function(){
            return reject(false);
        });
    });
}

export const callLang = (store) => {
    var language = window.localStorage.getItem(ID_LANG_KEY);
    if (!language)
    {
        /*if (window.navigator.languages) {
            language = window.navigator.languages[0];
        } 
        else {
            language = window.navigator.userLanguage || window.navigator.language;
        }*/
        language = "en-US";
        window.localStorage.setItem(ID_LANG_KEY, language);
    }

    var call = function(resolve, reject) {
        var store  = this.store;
        this.store.dispatch(API_TRANSLATIONS_ACTION_LOAD, language).then(function() {

            var i18n = new VueI18n({
                locale: language
            });

            i18n.setLocaleMessage(language, store.getters.getTranslationsMessage);
            console.log('resolve lang');
            return resolve(i18n);

        }).catch(function(){
            var i18n = new VueI18n({
                locale: 'en-US'
            });

            i18n.setLocaleMessage(language, store.getters.getTranslationsMessage);
            console.log('resolve lang');
            return resolve(i18n);
        });
    }
    return new Promise(call.bind({store})); 

}

export const getLocalesList = (store) => {
    var language_exp = window.localStorage.getItem(ID_LANG_EXP_KEY);
    var languages = window.localStorage.getItem(ID_LANG_LIST_KEY);

    if (!language_exp || language_exp < Date.now()) {
        var call = function (resolve, reject) {

            console.log('API_START_APP');
            store.dispatch(API_START_APP, this).
                //axios.get( (process.env.VUE_APP_TRANSLATIONS) + '/locales.json').
                then((data) => {

                    window.localStorage.setItem(ID_LANG_LIST_KEY, JSON.stringify(data.locales));
                    window.localStorage.setItem(ID_LANG_EXP_KEY, Date.now());

                    console.log('resolv locales');
                    return resolve();
                }).
                catch(() => {
                    return reject();
                });

        }
        return new Promise(call.bind());
    } else {
        var call = function (resolve, reject) {
            return resolve();
        }
        return new Promise(call.bind());
    }

}

export const getLang = () => {
    var language = window.localStorage.getItem(ID_LANG_KEY);
    if (!language)
    {
        language = "en-US";
        /*if (window.navigator.languages) {
            language = window.navigator.languages[0];
        } 
        else {
            language = window.navigator.userLanguage || window.navigator.language;
        }
        window.localStorage.setItem(ID_LANG_KEY, language);*/
    }
    return language;
}

export const getLocales = () => { 
    let lngs = window.localStorage.getItem(ID_LANG_LIST_KEY);
    let _ar = JSON.parse(lngs);
    return _ar;
};

export default { initI18n, getLang, getLocales };