import ApiService from "@/core/services/api.service";


//actions
export const API_DETAILS_HEATMAP_ACTION_LOAD = "action_details_heatmap_load";


//muts
export const API_DETAILS_HEATMAP_MUTS_SET = "muts_details_heatmap_set";

const state = {
    details: []
}

const getters = {
    getDetailsHeatmapMap() {
        return state.details;
    }
}

const actions = {
    [API_DETAILS_HEATMAP_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
                ApiService.post("heatmap/get_heatmap_detail_info", params, context)
                    .then(({ data }) => {
                        console.log(data);
                        context.commit(API_DETAILS_HEATMAP_MUTS_SET, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

        });
    }
}

const mutations = {
    [API_DETAILS_HEATMAP_MUTS_SET](state, {data}) {
        state.details = data.heatmap_details_info;
    }
}

export default {
    state,
    actions,
    mutations,
    getters
};