import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import store from "../store";
import { getAccess } from "@/core/services/user.group.access.service";

// action types add user
export const API_ADMIN_LOCATIONS_GET = "action_admin_locations_get";
export const API_ADMIN_LOCATIONS_EDIT = "action_admin_locations_edit";
export const API_ADMIN_LOCATIONS_ADD = "action_admin_locations_add";


export const API_ADMIN_LOCATIONS_GET_SCOPES = "action_admin_locations_get_scopes";
export const API_ADMIN_LOCATIONS_SCOPES_EDIT = "action_admin_locations_scopes_edit";
export const API_ADMIN_LOCATIONS_SCOPES_ADD = "action_admin_locations_scopes_add";
export const API_ADMIN_LOCATIONS_SCOPES_DELETE = "action_admin_locations_scopes_delete";

export const API_ADMIN_LOCATIONS_GET_VOCATIONS = "action_admin_locations_get_vocations";
export const API_ADMIN_LOCATIONS_GET_COUNTRIES = "action_admin_locations_get_countries";
export const API_ADMIN_LOCATIONS_GET_STATES = "action_admin_locations_get_states";
export const API_ADMIN_LOCATIONS_GET_FLEETS = "action_admin_locations_get_fleets";
export const API_ADMIN_LOCATIONS_GET_NONSIGS = "action_admin_locations_get_nonsigs";
export const API_ADMIN_LOCATIONS_SEARCH_ACCOUNTS = "action_admin_locations_search_accounts";
export const API_ADMIN_LOCATIONS_SEARCH_LOCATIONS = "action_admin_locations_search_locations";
export const API_ADMIN_LOCATIONS_LOCATION_CODES_BY_ID_LOAD = "action_admin_locations_load_location_codes_by_id"
export const API_ADMIN_LOCATIONS_LOCATION_CODES_BY_PROPERTIES_LOAD = "action_admin_locations_load_location_codes_by_properties";
export const API_ADMIN_LOCATIONS_LOCATION_CODES_BY_SUBSTRING_LOAD = "action_admin_locations_load_location_codes_by_substring"

export const API_ADMIN_LOCATIONS_EDIT_TIRE_SIZES_LOAD = "action_admin_locations_edit_tire_sizes_load";
export const API_ADMIN_LOCATIONS_EDIT_LOAD_RANGES_LOAD = "action_admin_locations_edit_load_ranges_load";

export const API_ADMIN_LOCATIONS_SEARCH_EXPORT_TO_PDF = "action_admin_locations_search_export_pdf";
export const API_ADMIN_LOCATIONS_SEARCH_EXPORT_TO_EXCEL = "action_admin_locations_search_export_excel";
export const API_ADMIN_LOCATIONS_SPECS_EXPORT_TO_PDF = "action_admin_locations_specs_export_pdf";
export const API_ADMIN_LOCATIONS_SPECS_EXPORT_TO_EXCEL = "action_admin_locations_specs_export_excel";

//muts
export const API_ADMIN_LOCATIONS_SET_PAGE_PARAMS = "muts_admin_locations_set_page";
export const API_ADMIN_LOCATIONS_SET_ACCOUNTS = "muts_admin_locations_set_accounts";
export const API_ADMIN_LOCATIONS_SET_LOCATIONS = "muts_admin_locations_set_locations";
export const API_ADMIN_LOCATIONS_SET_LIST = "muts_admin_locations_set_list";
export const API_ADMIN_LOCATIONS_SET_NONSIGS = "muts_admin_locations_set_nonsigs";
export const API_ADMIN_LOCATIONS_ERROR = "muts_admin_locations_error";
export const API_ADMIN_LOCATIONS_SET_LOCATION_CODES_BY_ID = "muts_admin_locations_set_location_codes_by_id";
export const API_ADMIN_LOCATIONS_SET_LOCATION_CODES_BY_SUBSTRING = "muts_admin_locations_set_location_codes_by_substring";

const state = {
    page_params: {
        us_regions: [],
        canada_region: []
    },
    nonsigs:[
        {
            value: null,
            text: null,
            is_selected: null
        }
    ],
    location_codes:[
    ],
    location_codes_by_search: {
        location_codes: [],
        total_items: 0
    },
    user_list: [],
    last_error: "",

    accounts_table: {
        items: [],
        page_number: null,
        page_size: null,
        total_items: 0,
    },
    locations_table: {
        items: [],
        page_number: null,
        page_size: null,
        total_items: 0,
    }
};

const getters = {
    getAdminLocationsPageParams() {
        return state.page_params
    },
    getAdminLocationsList() {
        return state.user_list
    },
    getAdminLocationsLastError() {
        return state.last_error
    },
    getAdminLocationsNonsigs() {
        return state.nonsigs;
    },
    getAdminLocationsLocationCodes() {
        return state.location_codes;
    },
    getAdminLocationsLocationCodesBySearch() {
        return state.location_codes_by_search;
    },
    getAdminAccountsTable() {
        return state.accounts_table;
    },
    getAdminLocationsTable() {
        return state.locations_table;
    }
};

const actions = {
    [API_ADMIN_LOCATIONS_GET](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.get("admin/locations/get/" + params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    }, 

    [API_ADMIN_LOCATIONS_EDIT](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.put("admin/locations/Edit", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_LOCATIONS_ADD](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/locations/add", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_LOCATIONS_GET_SCOPES](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/specs/get_specs", params, context)
                    .then(({ data }) => {
                        data.specs.forEach(element => {
                            if(element.load_range) {
                                if(!element.load_range.trim()) {
                                    element.load_range = null;
                                }
                            }
                        });
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    }, 

    [API_ADMIN_LOCATIONS_SCOPES_ADD](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/specs/add", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    }, 

    [API_ADMIN_LOCATIONS_SCOPES_EDIT](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.put("admin/specs/edit", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    }, 

    [API_ADMIN_LOCATIONS_SCOPES_DELETE](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.delete("admin/specs/delete/" + params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },


    [API_ADMIN_LOCATIONS_EDIT_TIRE_SIZES_LOAD](context) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.get("admin/specs/get_tire_sizes", context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_LOCATIONS_EDIT_LOAD_RANGES_LOAD](context) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.get("admin/specs/get_load_ranges", context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_LOCATIONS_GET_VOCATIONS](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/selects/get_vocations", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_LOCATIONS_GET_COUNTRIES](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.get("admin/selects/get_countries/NA", context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    
    [API_ADMIN_LOCATIONS_GET_STATES](context) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.get("admin/selects/get_states", context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_LOCATIONS_GET_FLEETS](context) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.get("admin/selects/get_fleets", context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_LOCATIONS_GET_NONSIGS](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.get("admin/selects/get_nonsigs/" + params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_NONSIGS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_LOCATIONS_SEARCH_ACCOUNTS](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/locations/select_accounts", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_ACCOUNTS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_ADMIN_LOCATIONS_LOCATION_CODES_BY_ID_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.get("admin/locations/get_locations_by_id/" +  params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_LOCATION_CODES_BY_ID, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_ADMIN_LOCATIONS_LOCATION_CODES_BY_SUBSTRING_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/locations/get_locations_by_properties", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_LOCATION_CODES_BY_SUBSTRING, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_ADMIN_LOCATIONS_LOCATION_CODES_BY_PROPERTIES_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/locations/get_locations_by_properties", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_LOCATION_CODES_BY_SUBSTRING, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_LOCATIONS_SEARCH_LOCATIONS](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/locations/select_locations", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_LOCATIONS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_LOCATIONS_SEARCH_EXPORT_TO_PDF](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.postBlob("admin/locations/download_pdf_file", params, context)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    
    [API_ADMIN_LOCATIONS_SEARCH_EXPORT_TO_EXCEL](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.postBlob("admin/locations/download_excel_file", params, context)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_ADMIN_LOCATIONS_SPECS_EXPORT_TO_PDF](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.postBlob("admin/specs/download_pdf_file", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    
    [API_ADMIN_LOCATIONS_SPECS_EXPORT_TO_EXCEL](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.postBlob("admin/specs/download_excel_file", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
};

const mutations = {    
    [API_ADMIN_LOCATIONS_SET_PAGE_PARAMS](state, data) {
        state.us_regions = data.us_regions;
        state.canada_region = data.canada_region;
    },
    [API_ADMIN_LOCATIONS_SET_PAGE_PARAMS](state, data) {
        state.user_list = data;
    },
    [API_ADMIN_LOCATIONS_SET_PAGE_PARAMS](state, detail) {
        state.last_error = detail;
    },
    [API_ADMIN_LOCATIONS_SET_NONSIGS](state, data) {
        state.nonsigs = data.nonsigs;
    },
    [API_ADMIN_LOCATIONS_SET_ACCOUNTS](state, data) {
        state.accounts_table.items = data.accounts;
        state.accounts_table.page_number = data.page_number;
        state.accounts_table.page_size = data.page_size;
        state.accounts_table.total_items = data.total_items;
    },
    [API_ADMIN_LOCATIONS_SET_LOCATIONS](state, data) {
        state.locations_table.items = data.locations.map(element => element = {
            account_information: element.account_information,
            contact_information: element.contact_information,
            location_information: element.location_information,
            id: element.id,
            name: element.location_information.location_name,
            address: element.location_information.address,
            city: element.location_information.city,
            state: element.location_information.state,
            zip: element.location_information.zip_code,
            preferred_flee_num: element.account_information.preferred_fleet_number,
            nonsig: element.account_information.non_sig
        });
        state.locations_table.page_number = data.page_number;
        state.locations_table.page_size = data.page_size;
        state.locations_table.total_items = data.total_items;
    },
    [API_ADMIN_LOCATIONS_SET_LOCATION_CODES_BY_ID](state, data) {
        let index = 0;
        state.location_codes = data ? data.map(el => el = {
            text: el.text,
            value: index++,
            real_value: el.value
        }) : [];

    },
    [API_ADMIN_LOCATIONS_SET_LOCATION_CODES_BY_SUBSTRING](state, data) {
        state.location_codes_by_search.total_items = data.total_items;
        let index = 0;
        state.location_codes_by_search.location_codes = data.location_codes ? data.location_codes.map(el => el = {
            text: el.text,
            value: index++,
            real_value: el.value
        }) : [];

    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
