import ApiService from "@/core/services/api.service";
import { getAccess } from "@/core/services/user.group.access.service";


// action types
export const API_DOCUMENT_CENTER_TAGS_ACTION_LOAD = "action_document_center_tags_load";
export const API_DOCUMENT_CENTER_DOCUMENTS_ACTION_LOAD = "action_document_center_documents_load";
export const API_DOCUMENT_CENTER_DOCUMENT_ACTION_DOWNLOAD = "action_document_center_document_download";
export const API_DOCUMENT_CENTER_DOCUMENT_ACTION_DELETE = "action_document_center_document_delete";
export const API_DOCUMENT_CENTER_DOCUMENT_ACTION_SAVE = "action_document_center_document_save";
export const API_DOCUMENT_CENTER_DOCUMENT_ACTION_LOAD = "action_document_center_document_load";

// muts
export const API_DOCUMENT_CENTER_MUTS_SET_TAGS = "muts_document_center_set_tags";
export const API_DOCUMENT_CENTER_MUTS_SET_DOCUMENTS = "muts_document_center_set_documents";
export const API_DOCUMENT_CENTER_MUTS_SET_DOCUMENT = "muts_document_center_set_document";
export const API_DOCUMENT_CENTER_MUTS_SET_PAGE_PARAMS = "muts_document_center_set_page_params";
export const API_DOCUMENT_CENTER_MUTS_SET_DOWNLOAD_PATH = "muts_document_center_set_download_path";
export const API_DOCUMENT_CENTER_MUTS_SET_STATUS = "muts_document_center_set_status";

const state = {
    tags: [
        {
            value: '',
            text: '',
            is_selected: null
        }
    ],
    documents: [
        {
            id: null,
            title: '',
            file_name: '',
            associated_tags: '',
            views_count: null,
            date: ''
        }
    ],
    document: {
        id: null,
        display_name: '',
        file_description: '',
        associated_tags: [
            {
                value: '',
                text: '',
                is_selected: null
            }
        ],
        user_groups: [
            {
                value: '',
                text: '',
                is_selected: null
            }
        ],
        is_user_aligned: null
    },
    pageParams: {
        page_number: null,
        page_size: null,
        total_items: null
    },
    path: '',
    status: {
        status: '',
        description: ''
    }


}

const getters = { 
    getAdminDocumentCenterTags() {
        return state.tags;
    },
    getAdminDocumentCenterDocuments() {
        return state.documents;
    },
    getAdminDocumentCenterDocument() {
        return state.document;
    },
    getAdminDocumentCenterPageParams() {
        return state.pageParams;
    },
    getAdminDocumentCenterDownloadPath() {
        return state.path;
    },
    getAdminDocumentCenterStatus() {
        return state.status;
    }
}

const actions = {
    [API_DOCUMENT_CENTER_TAGS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.get("documents/get_tags", context)
                    .then(({ data }) => {
                        context.commit(API_DOCUMENT_CENTER_MUTS_SET_TAGS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_DOCUMENT_CENTER_DOCUMENTS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("documents/get_documents", params, context)
                    .then(({ data }) => {
                        context.commit(API_DOCUMENT_CENTER_MUTS_SET_DOCUMENTS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    // [API_DOCUMENT_CENTER_DOCUMENT_ACTION_DOWNLOAD](context, params) {
    //     return new Promise((resolve, reject) => {
    //         ApiService.get("documents/download_document/" + params, context)
    //             .then(({ data }) => {
    //                 context.commit(API_DOCUMENT_CENTER_MUTS_SET_DOWNLOAD_PATH, {data});
    //                 resolve(data);
    //             })
    //             .catch(({ response }) => {
    //                 reject(response);
    //             });
    //     });
    // },
    [API_DOCUMENT_CENTER_DOCUMENT_ACTION_DELETE](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.delete("documents/delete_document/" + params, context)
                    .then(({ data }) => {
                        context.commit(API_DOCUMENT_CENTER_MUTS_SET_STATUS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_DOCUMENT_CENTER_DOCUMENT_ACTION_DOWNLOAD](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.postBlob("documents/download_document/" + params)
                    .then(({ data }) => {
                        // context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        // context.commit(API_ADMIN_LOCATIONS_SET_PAGE_PARAMS, response);
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_DOCUMENT_CENTER_DOCUMENT_ACTION_SAVE](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("documents/save_document", params, context)
                    .then(({ data }) => {
                        context.commit(API_DOCUMENT_CENTER_MUTS_SET_STATUS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_DOCUMENT_CENTER_DOCUMENT_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.get("documents/get_document/" + params, context)
                    .then(({ data }) => {
                        context.commit(API_DOCUMENT_CENTER_MUTS_SET_DOCUMENT, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
}


const mutations = {
    [API_DOCUMENT_CENTER_MUTS_SET_TAGS](state, {data}) {
        state.tags = data.tags;
    },
    [API_DOCUMENT_CENTER_MUTS_SET_DOCUMENT](state, {data}) {
        state.document = data.document;
    },
    [API_DOCUMENT_CENTER_MUTS_SET_DOCUMENTS](state, {data}) {
        state.documents = data.documents;
        state.pageParams.page_number = data.page_number;
        state.pageParams.page_size = data.page_size;
        state.pageParams.total_items = data.total_items;
    },
    [API_DOCUMENT_CENTER_MUTS_SET_STATUS](state, {data}) {
        state.status = data.status;
    },
}


export default {
    state,
    actions,
    mutations,
    getters
};