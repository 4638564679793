import Vue from "vue";
import Router from "vue-router";
import { API_ADMIN_IS_ONLY_LEASING_ACCESS } from "@/core/services/api/admin.user.module"

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: "/",
            //component: () => import("@/view/pages/banners/HomeBanner.vue"),
            name: "Index",
            component: () => import("@/view/pages/Home.vue"),
            meta: { requiresAuth: true, title: 'GoodYear Portal' },
            children:[

            ]
        }, 

        //dashboard
        {
            path: "/dashboard/fleet-central",
            name: "fleet-central-dashboard",
            component: () => import("@/view/pages/dashboard/fleetCentral/FleetCentral.vue"),
            meta: { requiresAuth: true, title: 'Fleet Central Dashboard' },
            
            beforeEnter: async (to, from, next) => {
                // check accesses, if only leasing redirect to TM
                const user = router.app.$store.getters.AppGetUser;
                if(user.Group === "CUS"){ 
                    try {
                        let {is_only_leasing_access, redirect_path} = await router.app.$store.dispatch(API_ADMIN_IS_ONLY_LEASING_ACCESS, user.Login)
                        if(is_only_leasing_access && redirect_path && redirect_path.trim() !=='') {           
                            const newPath = `${redirect_path}?user=${user.GyToken}`;
                            window.location.href = newPath;
                        }else{
                            next();
                        }
                    } catch (error) {
                        console.log(error);
                        next();
                    }
                }
                else{
                    next();
                }
            }
            
        }, 
        {
            path: "/dashboard/dealer-portal",
            name: "dealer-portal-dashboard",
            component: () => import("@/view/pages/dashboard/DealerPortal.vue"),
            meta: { requiresAuth: true, title: 'Dealer Dashboard' }
        },
        {
            path: "/dashboard/business-solutions",
            name: "business-solutions-dashboard",
            component: () => import("@/view/pages/dashboard/BusinessSolutions.vue"),
            meta: { requiresAuth: true, title: 'Business Solutions Dashboard' }
        },
        {
            path: "/dashboard/heat-map",
            name: "dashboard-heat-map",
            component: () => import("@/view/pages/dashboard/heatmap/HeatMap.vue"),
            meta: { requiresAuth: true, title: 'Heat Map' }
        },
        {
            path: "/dashboard/business-solutions/dealer-calendar",
            name: "business-solutions-dashboard-dealer-calendar",
            component: () => import("@/view/pages/dashboard/dealerPortalPages/DealerCalendar.vue"),
            meta: { requiresAuth: true, title: 'Dealer Calendar' }
        },
        
        //membership
        {
            path: "/membership/login",
            name: "Login",
            component: () => import("@/view/pages/membership/Login.vue"),
            meta: { requiresAuth: false, title: 'Login' }
        },
        {
            path: "/membership/logout",
            name: "Logout",
            component: () => import("@/view/pages/membership/Logout.vue"),
            meta: { requiresAuth: false, title: 'Logout' }
        },
        {
            path: "/membership/newregistration",
            name: "NewRegistration",
            component: () => import("@/view/pages/membership/NewRegistration.vue"),
            meta: { requiresAuth: false, title: 'Request Access' }
        },
        {
            path: "/membership/user-profile",
            name: "UserProfile",
            component: () => import("@/view/pages/membership/UserProfile.vue"),
            meta: { requiresAuth: true, title: 'Profile' }
        },
        
        //admin
        {
            path: "/admin",
            name: "Admin",
            component: () => import("@/view/pages/admin/index.vue"),
            meta: { requiresAuth: true }
        },
        {
            path: "/admin/users",
            name: "UsersManagement",
            component: () => import("@/view/pages/admin/users/UserSearch.vue"),
            meta: { requiresAuth: true, title: 'Users Management' }
        },
        { // admin/users/add
            path: "/admin/users/add",
            name: "ManagementUser_Add",
            component: () => import("@/view/pages/admin/users/AddUser.vue"),
            meta: { requiresAuth: true, title: 'Users Management, Add new user' }
            
        },
        { // admin/users/edit-id
            path: "/admin/users/edit-:id",
            name: "ManagementUser_Edit",
            component: () => import("@/view/pages/admin/users/AddUser.vue"),
            meta: { requiresAuth: true, title: 'Users Management, Modify user' }
        },
        {
            path: "/admin/management/sites",
            name: "SitesManagement",
            component: () => import("@/view/pages/admin/management/SitesManagement.vue"),
            meta: { requiresAuth: true, title: 'Sites Management' }
        },
        {
            path: "/admin/management/groups",
            name: "GroupsManagement",
            component: () => import("@/view/pages/admin/management/GroupsManagement.vue"),
            meta: { requiresAuth: true, title: 'Groups Management' }
        },
        {
            path: "/admin/management/links",
            name: "LinksManagement",
            component: () => import("@/view/pages/admin/management/LinksManagement.vue"),
            meta: { requiresAuth: true, title: 'Links Management' }
        },
        {
            path: "/admin/locations",
            name: "ManagmentLocation_Search",
            redirect: { name: 'Error-404' },
            component: () => import("@/view/pages/admin/locations/SearchLocation.vue"),
            meta: { requiresAuth: true, title: 'Location Management' }
        },
        { // admin/locations/add
            path: "/admin/locations/add",
            name: "ManagmentLocation_Add",
            redirect: { name: 'Error-404' },
            component: () => import("@/view/pages/admin/locations/AddLocation.vue"),
            meta: { requiresAuth: true, title: 'Location Management, Add new location' }
        },
        { // admin/locations/edit:id
            path: "/admin/locations/edit-:id",
            name: "ManagmentLocation_Edit",
            redirect: { name: 'Error-404' },
            component: () => import("@/view/pages/admin/locations/AddLocation.vue"),
            meta: { requiresAuth: true, title: 'Location Management, Modify location' }
        },
        {
            path: "/admin/localization",
            name: "Locale",
            component: () => import("@/view/pages/admin/localization/Locale.vue"),
            meta: { requiresAuth: true, title: 'Locales Management' }
        },
        { // admin/localization/en-us
            path: "/admin/localization/:locale",
            name: "Locale_locale",
            component: () => import("@/view/pages/admin/localization/Locale.vue"),
            meta: { requiresAuth: true, title: 'Locales Management, Modify locale' }
            
        },
        {
            path: '/admin/document-center',
            name: "ManageDocument",
            component: () => import("@/view/pages/admin/documentCenter/DocumentView.vue"),
            meta: { requiresAuth: true, title: 'Manage Documents' }
        },                        
        {
            path: '/admin/document-center/add',
            name: "ManageDocument_Add",
            component: () => import("@/view/pages/admin/documentCenter/components/AddEditDocument.vue"),
            meta: { requiresAuth: true, title: 'Manage Documents, Add document' }
        },
        {
            path: '/admin/document-center/doc-:id',
            name: "ManageDocument_Edit",
            component: () => import("@/view/pages/admin/documentCenter/components/AddEditDocument.vue"),
            meta: { requiresAuth: true, title: 'Manage Documents, Modify document' }
        },
        {
            path: "/admin/document-center/tags",
            name: "ManageTags",
            component: () => import("@/view/pages/admin/documentCenter/ManageTags.vue"),
            meta: { requiresAuth: true }
        },
        { 
            path: "/admin/document-center/tag-:id",
            name: "ManageTags_Edit",
            component: () => import("@/view/pages/admin/documentCenter/EditTag.vue"),
            meta: { requiresAuth: true }
            
        },
        {
            path: "/admin/security/manage-security",
            name: "SecurityManageSecurity",
            component: () => import("@/view/pages/admin/securityManagement/ManageSecurity.vue"),
            meta: { requiresAuth: true, title: 'Security Management' }
        },
        {
            path: "/admin/security/manage-user-groups",
            name: "SecurityUserGroups",
            component: () => import("@/view/pages/admin/securityManagement/UserGroups.vue"),
            meta: { requiresAuth: true, title: 'User Groups Management' }
        },
        {
            path: "/admin/manage-security-groups",
            name: "SecurityManageSecurityGroups",
            component: () => import("@/view/pages/admin/securityManagement/SecurityGroups.vue"),
            meta: { requiresAuth: true, title: 'Security Groups Management' }
        },
        { // admin/users/add
            path: "/admin/manage-security-groups/add",
            name: "SecurityManageSecurityGroups_Add",
            component: () => import("@/view/pages/admin/securityManagement/components/EditGroupSection.vue"),
            meta: { requiresAuth: true, title: 'Security Groups Management, Add new group' }
            
        },
        { // admin/users/edit-id
            path: "/admin/manage-security-groups/edit-:id",
            name: "SecurityManageSecurityGroups_Edit",
            component: () => import("@/view/pages/admin/securityManagement/components/EditGroupSection.vue"),
            meta: { requiresAuth: true, title: 'Security Groups Management, Modify group' }
        },        
        {
            path: "/document-center",
            name: "DocumentView",
            component: () => import("@/view/pages/resources/documentCenter/DocumentView.vue"),
            meta: { requiresAuth: true, title: 'Document Center' }
        },

        { path: "/Default.aspx", redirect: "/" },
        { path: "/login.aspx", redirect: "/membership/login" },
        { path: "/Logout.aspx", redirect: "/membership/logout" },
        { path: "/Membership/NewRegistration.aspx", redirect: "/membership/newregistration" },
        { path: "/Admin/Admin.aspx", redirect: "/admin" },
        { path: "/Admin/User/Edit.aspx", redirect: "/admin/users/add" },
        { path: "/Admin/User/Search.aspx", redirect: "/admin/users" },
        { path: "/Admin/DocumentCenter/ManageTags.aspx", redirect: "/admin/document-center/tags" },
        { path: "/Admin/SiteManagement/ManageSites.aspx", redirect: "/admin/management/sites" },
        { path: "/Admin/SiteManagement/ManageGroups.aspx", redirect: "/admin/management/groups" },
        { path: "/Admin/SiteManagement/ManageLinks.aspx", redirect: "/admin/management/links" },
        { path: "/Admin/Upload/Videos.aspx", redirect: "/admin" },
        { path: "/Admin/Upload/Documents.aspx", redirect: "/admin/document-center" },
        { path: "/Admin/SecurityManagement/ManageSecurity.aspx", redirect: "/admin/security/manage-security" },
        { path: "/Admin/SecurityManagement/ManageSecurityGroups.aspx", redirect: "/admin/security/manage-security-groups" },
        { path: "/Admin/SecurityManagement/ManageUserGroups.aspx", redirect: "/admin/security/manage-user-groups" },
        { path: "/DealerHome.aspx", redirect: "/dashboard/dealer-portal" },
        { path: "/DocumentCenter/DocumentView.aspx", redirect: "/document-center" },
        { path: "/Membership/UserProfile.aspx", redirect: "/membership/user-profile" },

        {
            path: "*",
            redirect: "/404"
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "Error-404",
            component: () => import("@/view/pages/error/404.vue"),
            meta: { requiresAuth: true }
        },
        {
            path: "/unauthorized",
            name: "Error-unauthorized",
            component: () => import("@/view/pages/error/Unauthorized.vue"),
            meta: { requiresAuth: true }
        },
    ]
});
export default router;

