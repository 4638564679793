import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { getAccess } from "@/core/services/user.group.access.service";

// action types
export const API_LOCALES_ACTION_LOAD = "action_admin_locales_load";
export const API_LOCALES_ACTION_ADD = "action_admin_locales_add";
export const API_LOCALES_ACTION_EDIT = "action_admin_locales_edit";
export const API_LOCALES_ACTION_ADD_AS = "action_admin_locales_add_as";
export const API_WORDS_ACTION_LOAD = "action_admin_words_load";
export const API_WORDS_ACTION_LOAD_COUNT = "action_admin_words_load_count";
export const API_WORDS_ACTION_ADD = "action_admin_words_add";
export const API_WORDS_ACTION_EDIT = "action_admin_words_edit";
export const API_TRANSLATIONS_ACTION_EDIT = "action_admin_translations_edit"

// muts
export const API_LOCALES_MUTS_SET_PAGE_PARAMS = "muts_admin_locales_set_page_params";
export const API_LOCALES_MUTS_SET_ERROR = "muts_admin-locales_set_error";
export const API_WORDS_MUTS_SET_ERROR = "muts_admin-words_set_error";
export const API_WORDS_MUTS_SET_COUNT = "muts_admin-words_set_count";
export const API_WORDS_MUTS_SET_PAGE_PARAMS = "muts_admin_words_set_page_params";

const state = {
    page_params: {
        localesForLocale: [
            {id: '', locale: '', parrentId: ''}
        ],
        locale: '',
        words: [
            {id: '', locale_id: '', translation_text: '', translated_text: '', comments: ''}
        ],
        page_number: null,
        page_size: null,
        total_items: null

    }, 
    error: {
        isError: null,
        description: '',
        status: ''
    },
    count: 0 
}

const getters = { 
    getAdminLocalesPageParams() {
        return state.page_params;
    },
    getAdminLocalesError() {
        return state.error;
    },
    getAdminWordsCount() {
        return state.count;
    }
};

const actions = {
    [API_LOCALES_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/locales/get", params, context)
                    .then(({ data }) => {
                        context.commit(API_LOCALES_MUTS_SET_PAGE_PARAMS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_LOCALES_ACTION_ADD](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post('admin/locales/add', params, context)
                    .then(({ data }) => {
                        context.commit(API_LOCALES_MUTS_SET_ERROR, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });  

            }
            else {
                reject();
            }
        })
    },
    [API_LOCALES_ACTION_EDIT](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put('admin/locales/edit', params, context)
                .then(({ data }) => {
                    context.commit(API_LOCALES_MUTS_SET_ERROR, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });  
        })
    },
    [API_LOCALES_ACTION_ADD_AS](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post('admin/locales/add_as', params, context)
                    .then(({ data }) => {
                        context.commit(API_LOCALES_MUTS_SET_ERROR, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });  

            }
            else {
                reject();
            }
        })
    },
    [API_WORDS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/translations/get_words", params, context)
                    .then(({ data }) => {
                        context.commit(API_WORDS_MUTS_SET_PAGE_PARAMS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_WORDS_ACTION_LOAD_COUNT](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/translations/get_words_count", params, context)
                    .then(({ data }) => {
                        // console.log('in action API_WORDS_ACTION_LOAD_COUNT');
                        context.commit(API_WORDS_MUTS_SET_COUNT, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_WORDS_ACTION_ADD](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post('admin/translations/add_word', params, context)
                    .then(({ data }) => {
                        context.commit(API_WORDS_MUTS_SET_ERROR, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });  

            }
            else {
                reject();
            }
        })
    },
    [API_TRANSLATIONS_ACTION_EDIT](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.put('admin/translations/edit_words', params, context)
                    .then(({ data }) => {
                        context.commit(API_WORDS_MUTS_SET_ERROR, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });  

            }
            else {
                reject();
            }
        })
    },
    [API_WORDS_ACTION_EDIT](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.put('admin/translations/edit_dictionary_words', params, context)
                    .then(({ data }) => {
                        context.commit(API_WORDS_MUTS_SET_ERROR, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });  

            }
            else {
                reject();
            }
        })
    },

};

const mutations = {
    [API_LOCALES_MUTS_SET_PAGE_PARAMS](state, {data}) {
        state.page_params.localesForLocale = data.locales;
    },
    [API_LOCALES_MUTS_SET_ERROR](state, {data}) {
        state.error.description = data.status.description;
        state.error.isError = true;
        state.error.status = data.status.status;
    },
    [API_WORDS_MUTS_SET_ERROR](state, {data}) {
        state.error.description = data.status.description;
        state.error.isError = true;
        state.error.status = data.status.status;
    },
    [API_WORDS_MUTS_SET_COUNT](state, {data}) {
        state.count = data.count;
    },
    [API_WORDS_MUTS_SET_PAGE_PARAMS](state, {data}) {
        state.page_params.words = data.words;
        state.page_params.page_number = data.page_number;
        state.page_params.page_size = data.page_size;
        state.page_params.total_items = data.total_items;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};