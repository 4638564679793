import ApiService from "@/core/services/api.service";


//actions
export const API_DLR_HEATMAP_ACTION_LOAD = "action_dlr_heatmap_load";
export const API_DLR_DEALERS_ACTION_LOAD = "action_dlr_dealers_load";
export const API_DLR_DEALER_LOCATIONS_ACTION_LOAD = "action_dlr_dealer_locations_load";
export const API_DLR_DEALER_CTSC_REGIONS_ACTION_LOAD = "action_dlr_dealer_ctsc_regions_load";
export const API_DLR_DEALER_SUB_OWNERS_ACTION_LOAD = "action_dlr_dealer_sub_owners_load";

export const API_DLR_NA_FLEET_ACTION_LOAD = "action_dlr_na_fleet_load";
export const API_DLR_CA_OWNER_ACTION_LOAD = "action_dlr_ca_owner_load";
export const API_DLR_PR_FLEET_ACTION_LOAD = "action_dlr_pr_fleet_load";
export const API_DLR_CA_NONSIG_ACTION_LOAD = "action_dlr_ca_nonsig_load";
export const API_DLR_LOCATION_ACTION_LOAD = "action_dlr_locations_load";


//muts
export const API_DLR_HEATMAP_MUTS_SET_MAP = "muts_dlr_heatmap_set_map";
export const API_DLR_HEATMAP_MUTS_SET_STAT = "muts_dlr_heatmap_set_stat";
export const API_DLR_MUTS_SET_DEALER = "muts_dlr_set_dealers";
export const API_DLR_MUTS_SET_DEALER_LOCATIONS = "muts_dlr_set_dealer_locations";
export const API_DLR_MUTS_SET_DEALER_CTSC_REGIONS = "muts_dlr_set_dealer_ctsc_regions";
export const API_DLR_MUTS_SET_DEALER_SUB_OWNERS = "muts_dlr_set_dealer_sub_owners";

export const API_DLR_MUTS_SET_NA_FLEET = "muts_dlrt_set_na_fleet";
export const API_DLR_MUTS_SET_CA_OWNER = "muts_dlr_set_ca_owner";
export const API_DLR_MUTS_SET_PR_FLEET = "muts_dlr_set_pr_fleet";
export const API_DLR_MUTS_SET_CA_NONSIG = "muts_dlr_set_ca_nonsig";
export const API_DLR_MUTS_SET_LOCATION = "muts_dlr_set_location";

const state = {
    boxesData: {
        serviceEvents: null,
        serviceETA: null,
        serviceATA: null,
        serviceDealerEmergRoll: null,
        serviceFleetEmergRoll: null,
        serviceSmartTech: null
    },
    mapData: [],
    dealers: [
        {text: null, value: null}
    ],
    dealers_locations: [],
    dealers_sub: [],


    na_fleets: [
        
    ],
    ca_ows: [
        
    ],
    pr_fleets: [
        
    ],
    ca_nons: [
        
    ],
    customer_locations: [

    ],
}

const getters = {
    getDlrHeatmapMapData() {
        return state.mapData;
    },
    getDlrHeatmapBoxes() {
        return state.boxesData;
    },
    getDlrDealers() {
        return state.dealers;
    },
    getDlrDealerLocations() {
        return state.dealers_locations;
    },
    getDlrDealerSubOwners() {
        return state.dealers_sub;
    },

    getDlrNaFleets() {
        return state.na_fleets;
    },
    getDlrCaOwners() {
        return state.ca_ows;
    },
    getDlrPrFleets() {
        return state.pr_fleets;
    },
    getDlrCaNonsigs() {
        return state.ca_nons;
    },
    getDlrLocations() {
        return state.customer_locations;
    }
}

const actions = {
    [API_DLR_HEATMAP_ACTION_LOAD](context, params) {
        let cancelTokenSource = params.cancelTokenSource;
        delete params.cancelTokenSource;
        return new Promise((resolve, reject) => {
                ApiService.post("heatmap/dealers-map", params, context, cancelTokenSource)
                    .then(({ data }) => {
                        context.commit(API_DLR_HEATMAP_MUTS_SET_MAP, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

        });
    },
    [API_DLR_DEALERS_ACTION_LOAD](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("heatmap/get_dealers", context)
                .then(({ data }) => {
                    context.commit(API_DLR_MUTS_SET_DEALER, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_DLR_DEALER_LOCATIONS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("heatmap/get_dealer_locations", params, context)
                .then(({ data }) => {
                    context.commit(API_DLR_MUTS_SET_DEALER_LOCATIONS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_DLR_DEALER_CTSC_REGIONS_ACTION_LOAD](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("heatmap/get_dealer_ctsc_locations", context)
                .then(({ data }) => {
                    context.commit(API_DEALER_PORTAL_MUTS_SET_LOCATIONS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_DLR_DEALER_SUB_OWNERS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("heatmap/get_dealer_sub_owners", params, context)
                .then(({ data }) => {
                    context.commit(API_DLR_MUTS_SET_DEALER_SUB_OWNERS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },


    [API_DLR_NA_FLEET_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("heatmap/get_fleet_na_full", params, context)
                .then(({ data }) => {
                    context.commit(API_DLR_MUTS_SET_NA_FLEET, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_DLR_CA_OWNER_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("heatmap/get_fleet_ca_ow_full", params, context)
                .then(({ data }) => {
                    context.commit(API_DLR_MUTS_SET_CA_OWNER, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_DLR_PR_FLEET_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("heatmap/get_fleet_pr_fl_full", params, context)
                .then(({ data }) => {
                    context.commit(API_DLR_MUTS_SET_PR_FLEET, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_DLR_CA_NONSIG_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("heatmap/get_fleet_ca_non_full", params, context)
                .then(({ data }) => {
                    context.commit(API_DLR_MUTS_SET_CA_NONSIG, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_DLR_LOCATION_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("heatmap/get_fleet_loc_full", params, context)
                .then(({ data }) => {
                    context.commit(API_DLR_MUTS_SET_LOCATION, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}

const mutations = {
    [API_DLR_HEATMAP_MUTS_SET_MAP](state, {data}) {
        state.mapData = data.data.mapData;
        state.boxesData.serviceEvents = data.data.serviceEvents;
        state.boxesData.serviceETA = data.data.serviceETA; 
        state.boxesData.serviceATA = data.data.serviceATA;
        state.boxesData.serviceDealerEmergRoll = data.data.serviceDealerEmergRoll;
        state.boxesData.serviceFleetEmergRoll = data.data.serviceFleetEmergRoll;
    },
    [API_DLR_MUTS_SET_DEALER](state, {data}) {
        state.dealers = data.dealers;
    },
    [API_DLR_MUTS_SET_DEALER_LOCATIONS](state, {data}) {
        state.dealers_locations = data.dealers_locations;
    },
    [API_DLR_MUTS_SET_DEALER_CTSC_REGIONS](state, {data}) {
        state.dealers_locations = data.dealers_locations;
    },
    [API_DLR_MUTS_SET_DEALER_SUB_OWNERS](state, {data}) {
        state.dealers_sub = data.dealers_sub;
    },


    [API_DLR_MUTS_SET_NA_FLEET](state, {data}) {
        state.na_fleets = data.data;
    },
    [API_DLR_MUTS_SET_CA_OWNER](state, {data}) {
        state.ca_ows = data.data;
    },
    [API_DLR_MUTS_SET_PR_FLEET](state, {data}) {
        state.pr_fleets = data.data;
    },
    [API_DLR_MUTS_SET_CA_NONSIG](state, {data}) {
        state.ca_nons = data.data;
    },
    [API_DLR_MUTS_SET_LOCATION](state, {data}) {
        state.customer_locations = data.data;
    }
}

export default {
    state,
    actions,
    mutations,
    getters
};