import Enum from '@/utils/helpers/EnumHelper.js';


const Statuses = Enum({
    ACTIVE: "A",
    INACTIVE: "I",
});

export {
    Statuses
};
