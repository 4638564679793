import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service";

// action types
export const API_TRANSLATIONS_ACTION_LOAD = "action_admin_translations_load";

// muts
export const API_TRANSLATIONS_MUTS_SET_MESSAGE = "muts_admin_translations_set_messages";

const state = {
    message: {}
}

const getters = { 
    getTranslationsMessage() {
        return state.message
    },
    getTranslationsSelectedLocale() {
        return i18nService.getLang();
    },
    getTranslationsLocales() {
       
        return i18nService.getLocales();
    },
};

const actions = {
    [API_TRANSLATIONS_ACTION_LOAD](context, params) {
        var axios = null;
        if (Object.prototype.hasOwnProperty.call(window, 'app')) {
            var axios = window.app.axios;
        } 
        
        return new Promise((resolve, reject) => {
            //if (axios) axios.defaults.baseURL = process.env.VUE_APP_API_SERVER_HOST;  
            let _append = "";
            try {
                new URL(process.env.VUE_APP_TRANSLATIONS);
            } catch (_) {
                _append = location.origin;
            }
            ApiService.get(_append + process.env.VUE_APP_TRANSLATIONS + "/" + params + ".json", context)
                .then(({ data }) => {
                    console.log('in API_TRANSLATIONS_ACTION_LOAD then');
                    context.commit(API_TRANSLATIONS_MUTS_SET_MESSAGE, {data});
                    return resolve(data);
                })
                .catch(({ response }) => {
                    console.log('in API_TRANSLATIONS_ACTION_LOAD cathc');
                    return reject(response);
                });
            //if (axios) axios.defaults.baseURL = (process.env.VUE_APP_API_SERVER); 
        });
    }
};

const mutations = {
    [API_TRANSLATIONS_MUTS_SET_MESSAGE](state, {data}) {
        state.message = data;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};