<template>
    <div id="app-loader">
        <div class="gy-spinner-container">
            <div class="loader-title">{{ $t(title) }}</div>
            <span class="gy-spinner-icon"></span>    
            <div class="preloader-wrapper big active">        
                <div class="spinner-layer spinner-blue-only">            
                    <div class="circle-clipper left">                
                        <div class="circle">
                        </div>            
                    </div>
                    <div class="gap-patch">                
                        <div class="circle">
                        </div>            
                    </div>
                    <div class="circle-clipper right">                
                        <div class="circle">
                        </div>            
                    </div>        
                </div>    
            </div>
        </div>  
        <div v-if="description" class="text-center">{{description}}</div>      
    </div>
</template>
<script>

export default {
    data(){
        return {  
            title: "Loading...",
            queues: [], // element example: { name: 'page1', count: 0 }
            description: "",
        }
    },
    methods: {
        showLoader: function(title, queueName, description){
            this.title = title;
            this.description = description;

            if (queueName) {
                let foundQueue = this.queues.find(queue => queue.name === queueName);
                if (foundQueue) {
                    foundQueue.count += 1;
                }
                else {
                    this.queues.push({ name: queueName, count: 1 });
                }
            }

            document.body.classList.add("app-loader-open");
            document.getElementById("app-loader").classList.add("d-block");

        },
        closeLoader: function(queueName) {
            if(queueName){
                let foundQueue = this.queues.find(queue => queue.name === queueName);
                if (foundQueue) {
                    foundQueue.count -= 1;
                    if (foundQueue.count < 1) {
                        let foundQueueIndex = this.queues.findIndex(queue => queue.name === queueName);
                        if (foundQueueIndex != -1) {
                            this.queues.splice(foundQueueIndex, 1);
                        }
                    }
                }
            }

            if (this.queues.length == 0) {
                this.title = "";

                document.body.classList.remove("app-loader-open");
                document.getElementById("app-loader").classList.remove("d-block");
            }
        }
         
    },
    created() {
        var div = document.createElement('div');
        div.className = "loader-backdrop";
        document.body.appendChild(div);

        this.$root.$on('show-loader', (title, queue, description) => {
            this.showLoader(title, queue, description);
        });
        this.$root.$on('close-loader', queue => { this.closeLoader(queue); });
    },

    beforeDestroy() {
        this.$root.$off('show-loader');
        this.$root.$off('close-loader');
    }
     
};
</script>
<style>
    .loader-backdrop {
        display: none;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        position: fixed;
        z-index: 2040;
        background-color: #000;
        opacity: 0.5;
    }
    body.app-loader-open {
        overflow: hidden;
    }
    body.app-loader-open .loader-backdrop{
        display: block;
    }

    #app-loader {
        position: fixed;
        z-index: 2041;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: none;
    }

    body.app-loader-open {
        display: block;
    }

    #app-loader .loader-title {
        font-size: 2em;
        font-weight: 100;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
    }
</style>