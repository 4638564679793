import ApiService from "@/core/services/api.service";
import { getAccess } from "@/core/services/user.group.access.service";

// action types
export const API_ADMIN_MANAGE_TAGS_ACTION_LOAD_TAGS = "action_admin_manage_tags_load_tags";
export const API_ADMIN_MANAGE_TAGS_ACTION_TAG_ADD = "action_admin_manage_tags_tag_add";
export const API_ADMIN_MANAGE_TAGS_ACTION_TAG_GET = "action_admin_manage_tags_tag_get";
export const API_ADMIN_MANAGE_TAGS_ACTION_TAG_DELETE = "action_admin_manage_tags_tag_delete";
export const API_ADMIN_MANAGE_TAGS_ACTION_TAG_EDIT = "action_admin_manage_tags_tag_edit";


// muts
export const API_ADMIN_MANAGE_TAGS_MUTS_SET_TAGS = "muts_admin_manage_tags_set_tags";
export const API_ADMIN_MANAGE_TAGS_MUTS_SET_TAG = "muts_admin_manage_tags_set_tag";
export const API_ADMIN_MANAGE_TAGS_MUTS_SET_STATUS = "muts_admin_manage_tags_set_status";

const state = {
    tags: [
        {
            id: null,
            name: '',
            description: ''
        }
    ],
    page_params: {
        page_number: null,
        page_size: null,
        total_items: null
    },
    status: {
        status: '',
        description: ''
    },
    tag: {
        id: null,
        name: '',
        description: ''
    }
}

const getters = { 
    getAdminManageTagsTags() {
        return state.tags;
    },
    getAdminManageTagsTag() {
        return state.tag;
    },
    getAdminManageTagsPageParams() {
        return state.page_params;
    },
    getAdminManageTagsStatus() {
        return state.status;
    },

};

const actions = {
    [API_ADMIN_MANAGE_TAGS_ACTION_LOAD_TAGS](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("tags/get", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_MANAGE_TAGS_MUTS_SET_TAGS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_ADMIN_MANAGE_TAGS_ACTION_TAG_ADD](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("tags/add", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_MANAGE_TAGS_MUTS_SET_STATUS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_ADMIN_MANAGE_TAGS_ACTION_TAG_DELETE](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.delete("tags/delete/" + params, context)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_ADMIN_MANAGE_TAGS_ACTION_TAG_GET](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.get("tags/get_by_id/" + params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_MANAGE_TAGS_MUTS_SET_TAG, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
    [API_ADMIN_MANAGE_TAGS_ACTION_TAG_EDIT](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.put("tags/edit", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_MANAGE_TAGS_MUTS_SET_STATUS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
}

const mutations = {
    [API_ADMIN_MANAGE_TAGS_MUTS_SET_TAGS](state, {data}) {
        state.tags = data.tags;
        state.page_params.page_number = data.page_number;
        state.page_params.page_size = data.page_size;
        state.page_params.total_items = data.total_items;
    },
    [API_ADMIN_MANAGE_TAGS_MUTS_SET_STATUS](state, {data}) {
        state.status = data.status;
    },
    [API_ADMIN_MANAGE_TAGS_MUTS_SET_TAG](state, {data}) {
        state.tag = data.tag;
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};