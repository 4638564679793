import ApiService from "@/core/services/api.service";
import { getAccess } from "@/core/services/user.group.access.service";

export const API_ADMIN_COMMON_GET_CUSTOMER_LOCATION_GROUPS = "action_admin_common_get_customer_location_groups"
export const API_ADMIN_COMMON_GET_CUSTOMER_LOCATION_REGIONS = "action_admin_common_get_customer_location_regions"
export const API_ADMIN_COMMON_GET_CAUSE_OF_FAILURES = "action_admin_common_get_cause_of_failures"

export const API_ADMIN_COMMON_SET_CUSTOMER_LOCATION_GROUPS = "muts_admin_common_set_customer_location_groups"
export const API_ADMIN_COMMON_SET_CUSTOMER_LOCATION_REGIONS = "muts_admin_common_set_customer_location_regions"
export const API_ADMIN_COMMON_SET_CAUSE_OF_FAILURES = "muts_admin_common_set_cause_of_failures"

const action_state = {
    not_called: 0,
    loaded: 1,
    in_proccess: 2,
};

const state = {
    location_groups: null,
    location_regions: null,
    cause_of_failures: [],
    loading_now: {
        cause_of_failures: action_state.not_called,
    },
};

const getters = {
    getAdminCommonLocationGroups() {
        return state.location_groups
    },
    getAdminCommonLocationRegions() {
        return state.location_regions
    },
    getAdminCommonCauseOfFailures() {
        return state.cause_of_failures
    },
};

const actions = {
    [API_ADMIN_COMMON_GET_CUSTOMER_LOCATION_GROUPS](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/selects/get_customer_location_groups", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_COMMON_SET_CUSTOMER_LOCATION_GROUPS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject(new Error('Error in getAccess for call promise API_ADMIN_COMMON_GET_CUSTOMER_LOCATION_GROUPS.'));
            }
        });
    },

    [API_ADMIN_COMMON_GET_CUSTOMER_LOCATION_REGIONS](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/selects/get_customer_location_regions", params, context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_COMMON_SET_CUSTOMER_LOCATION_REGIONS, data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject(new Error('Error in getAccess for call promise API_ADMIN_COMMON_GET_CUSTOMER_LOCATION_REGIONS.'));
            }
        });
    },

    [API_ADMIN_COMMON_GET_CAUSE_OF_FAILURES](context, params) {
        return new Promise((resolve, reject) => {
            if(context.state.loading_now.cause_of_failures == action_state.not_called && getAccess()) {
                context.state.loading_now.cause_of_failures = action_state.in_proccess;
                ApiService.get("admin/selects/get_cause_of_failures", context)
                    .then(({ data }) => {
                        context.commit(API_ADMIN_COMMON_SET_CAUSE_OF_FAILURES, data);
                        context.state.loading_now.cause_of_failures = action_state.loaded;
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        context.state.loading_now.cause_of_failures = action_state.not_called;
                        reject(response);
                    });

            }
            else if (context.state.loading_now.cause_of_failures != action_state.not_called){
                resolve();
            } 
            else {
                reject(new Error('Error in getAccess for call promise API_ADMIN_COMMON_GET_CAUSE_OF_FAILURES.'));
            }
        });
    },
};

const mutations = {    
    [API_ADMIN_COMMON_SET_CUSTOMER_LOCATION_GROUPS](state, data) {
        state.location_groups = data;
    },
    [API_ADMIN_COMMON_SET_CUSTOMER_LOCATION_REGIONS](state, data) {
        state.location_regions = data;
    },
    [API_ADMIN_COMMON_SET_CAUSE_OF_FAILURES](state, data) {
        state.cause_of_failures = data;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};