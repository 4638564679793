import ApiService from "@/core/services/api.service";


//actions
 
export const API_FLEET_HEATMAP_ACTION_LOAD = "action_fleet_heatmap_load";
export const API_FLEET_NA_FLEET_ACTION_LOAD = "action_fleet_na_fleet_load";
export const API_FLEET_CA_OWNER_ACTION_LOAD = "action_fleet_ca_owner_load";
export const API_FLEET_PR_FLEET_ACTION_LOAD = "action_fleet_pr_fleet_load";
export const API_FLEET_CA_NONSIG_ACTION_LOAD = "action_fleet_ca_nonsig_load";
export const API_FLEET_LOCATION_ACTION_LOAD = "action_fleet_locations_load";

export const API_FLEET_DEALERS_ACTION_LOAD = "action_fleet_dealers_load";
export const API_FLEET_DEALER_LOCATIONS_ACTION_LOAD = "action_fleet_dealer_locations_load";

//muts
export const API_FLEET_HEATMAP_MUTS_SET_MAP = "muts_fleet_heatmap_set_map";
export const API_FLEET_HEATMAP_MUTS_SET_STAT_MAP = "muts_fleet_heatmap_set_stat";
export const API_FLEET_MUTS_SET_NA_FLEET = "muts_fleet_set_na_fleet";
export const API_FLEET_MUTS_SET_CA_OWNER = "muts_fleet_set_ca_owner";
export const API_FLEET_MUTS_SET_PR_FLEET = "muts_fleet_set_pr_fleet";
export const API_FLEET_MUTS_SET_CA_NONSIG = "muts_fleet_set_ca_nonsig";
export const API_FLEET_MUTS_SET_LOCATION = "muts_fleet_set_location";

export const API_FLEET_MUTS_SET_DEALER = "muts_fleet_set_dealers";
export const API_FLEET_MUTS_SET_DEALER_LOCATIONS = "muts_fleet_set_dealer_locations";

const state = {
    na_fleets: [
        {
            text: null,
            value: null
        }
    ],
    ca_ows: [
        {
            text: null,
            value: null
        }
    ],
    pr_fleets: [
        {
            text: null,
            value: null
        }
    ],
    ca_nons: [
        {
            text: null,
            value: null
        }
    ],
    customer_locations: [

    ],
    heat_map: [],
    stat: {
        active_calls: null,
        roll_time: null,
        mtd_calls: null,
        completed_calls: null,
    },
    dealers: [
    ],
    dealers_locations: [
    ],
    dealers_sub: [
    ]
}

const getters = {
    getFleetHeatmapMapData() {
        return state.heat_map;
    },
    getFleetHeatmapBoxesData() {
        return state.stat;
    },
    getFleetNaFleets() {
        return state.na_fleets;
    },
    getFleetCaOwners() {
        return state.ca_ows;
    },
    getFleetPrFleets() {
        return state.pr_fleets;
    },
    getFleetCaNonsigs() {
        return state.ca_nons;
    },
    getFleetLocations() {
        return state.customer_locations;
    },
    getFlDealers() {
        return state.dealers;
    },
    getFlDealerLocations() {
        return state.dealers_locations;
    },
    getFlDealerSubOwners() {
        return state.dealers_sub;
    }
}

const actions = {
    [API_FLEET_HEATMAP_ACTION_LOAD](context, params) {
        let cancelTokenSource = params.cancelTokenSource;
        delete params.cancelTokenSource;
        return new Promise((resolve, reject) => {
            ApiService.post("heatmap/fleets-map", params, context, cancelTokenSource)
                .then(({ data }) => {
                    context.commit(API_FLEET_HEATMAP_MUTS_SET_MAP, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    
    [API_FLEET_NA_FLEET_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("heatmap/get_fleet_na", params, context)
                .then(({ data }) => {
                    context.commit(API_FLEET_MUTS_SET_NA_FLEET, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FLEET_CA_OWNER_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("heatmap/get_fleet_ca_ow", params, context)
                .then(({ data }) => {
                    context.commit(API_FLEET_MUTS_SET_CA_OWNER, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FLEET_PR_FLEET_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("heatmap/get_fleet_pr_fl", params, context)
                .then(({ data }) => {
                    context.commit(API_FLEET_MUTS_SET_PR_FLEET, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FLEET_CA_NONSIG_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("heatmap/get_fleet_ca_non", params, context)
                .then(({ data }) => {
                    context.commit(API_FLEET_MUTS_SET_CA_NONSIG, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FLEET_LOCATION_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("heatmap/get_fleet_loc", params, context)
                .then(({ data }) => {
                    context.commit(API_FLEET_MUTS_SET_LOCATION, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },

    [API_FLEET_DEALERS_ACTION_LOAD](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("heatmap/get_dealers_full", context)
                .then(({ data }) => {
                    context.commit(API_FLEET_MUTS_SET_DEALER, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FLEET_DEALER_LOCATIONS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("heatmap/get_dealer_locations_full", params, context)
                .then(({ data }) => {
                    context.commit(API_FLEET_MUTS_SET_DEALER_LOCATIONS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }
}

const mutations = {
    [API_FLEET_HEATMAP_MUTS_SET_MAP](state, {data}) {
        state.heat_map = data.data.heatMaps;
        state.stat.active_calls = data.data.active_calls;
        state.stat.completed_calls = data.data.completed_calls;
        state.stat.roll_time = data.data.roll_time;
        state.stat.mtd_calls = data.data.mtd_calls;
        
    },
    [API_FLEET_MUTS_SET_NA_FLEET](state, {data}) {
        state.na_fleets = data.data;
    },
    [API_FLEET_MUTS_SET_CA_OWNER](state, {data}) {
        state.ca_ows = data.data;
    },
    [API_FLEET_MUTS_SET_PR_FLEET](state, {data}) {
        state.pr_fleets = data.data;
    },
    [API_FLEET_MUTS_SET_CA_NONSIG](state, {data}) {
        state.ca_nons = data.data;
    },
    [API_FLEET_MUTS_SET_LOCATION](state, {data}) {
        state.customer_locations = data.data;
    },

    [API_FLEET_MUTS_SET_DEALER](state, {data}) {
        state.dealers = data.dealers;
    },
    [API_FLEET_MUTS_SET_DEALER_LOCATIONS](state, {data}) {
        state.dealers_locations = data.dealers_locations;
    }
}

export default {
    state,
    actions,
    mutations,
    getters
};