<template>
    <div id="notify">
        <div class="notify-content">
            <div class="notify-header">
                <div class="notify-cross">
                    <b-icon-x-circle
                        @click="close"
                        >
                    </b-icon-x-circle>
                </div>
            </div>
            <div class="notify-body">
                <div class="notify-message" v-if="emptyTranslationsCount">
                    
                    <div class="row count-words">
                        <div class="col-md-12">
                            <div class="notify-message-header">
                                <span>Help us</span>
                                to improve our portal
                            </div>
                        </div>
                    </div>
                    <div class="row count-words">
                        <div class="col-md-12">
                             <i>{{emptyTranslationsCount}}</i>
                        </div>
                    </div>
                    <div class="row count-words">
                        <div class="col-md-12">
                            Untranslated<br />phrases in<br/> the locale</div>
                        </div>
                    <p></p>

                    <p class="text-center">
                        <router-link :to="{ name: 'Locale_locale', params: { locale: locale.locale } }">View phrases</router-link>
                    </p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
// api
import { mapGetters, mapActions } from 'vuex';
import { API_WORDS_ACTION_LOAD_COUNT } from "@/core/services/api/admin.locales.module.js";
import i18nService from "@/core/services/i18n.service";

export default {
    data() {
        return {
            title: '',
            emptyTranslationsCount: '',
            locale: {
                id: 1,
                locale: 'de-DE',
                parent_id: null
            }
        }
    },
    methods: {
        close() {
            this.$emit('close');
        }, 
        closeNotify() {
            //document.body.classList.remove("notify-open");
            //document.getElementById("notify").classList.remove("show");
        },
        showNotify(title) {
            if(title) {
                this.title = title;
            }
            // if(wordsCount) {
            //     this.emptyTranslationsCount = wordsCount;
            // }
            //document.body.classList.add("notify-open");
            //document.getElementById("notify").classList.add("show");
        }
    },
    computed: {
        ...mapGetters({
            WordsCount: "getAdminWordsCount",       
            IsAuthenticated: "AppIsAuthenticated",
            GetUser: "AppGetUser"
        }) 
    },
    mounted() {
       
        /*if (this.IsAuthenticated == true && this.GetUser.Group == "GYR") {
            this.locale.id = i18nService.getLang();
            if (this.locale.id != 'en-US') {
                var _self = this;
                this.$store.dispatch(API_WORDS_ACTION_LOAD_COUNT, {
                    locale: this.locale.id,
                    filter_by_translation_text: '',
                    filter_by_comments: '',
                    filter_by_translated_text: '',
                    filter_by_include_empty: false,
                    filter_by_only_empty: true,
                    page_info: {
                        page_number: 1,
                        page_size: null
                    }
                })
                .then((data) => {
                    _self.emptyTranslationsCount = data.count;
                    _self.showNotify();
                })
                .catch((e) => {
                    //console.log(e);
                    this.emptyTranslationsCount = ' lot of';
                });
            }          

            
        } */       

    },
    created() {
        
        /*this.$root.$on('show-notify', (title) => {
            this.showNotify(title);
        });

        this.$root.$on('close-notify', () => {this.closeNotify(); });*/

    },

    beforeDestroy() {
        /*this.$root.$off('show-notify');
        this.$root.$off('close-notify');*/
    }
}
</script>

<style>
#notify {
    position: absolute;
    
    background: linear-gradient(to bottom right, #B0DB7D 40%, #99DBB4 100%);
    border-radius: 20px;
    box-shadow: 5px 5px 20px rgb(203 205 211 / 10%);
    perspective: 40px;

    position: fixed;
    right:5px;
    bottom:5px;
    display: none;
    padding: 15px 30px;
    z-index: 10;
}
.notify-title>h2 {
    font-size: 15px;
    margin-top: 0px;
    margin-bottom: 0px;
}
body.notify-open {
    overflow: scroll;
}
/* body.notify-open {
    display: block;
} */
.notify-cross {
    /* margin-left: 280px; */
    margin-top: 2px;
    margin-right: 2px;
    position: absolute;
    right: 0px;
    top: 0px;
    font-size: 24px;
}
.notify-body {
    height: 100%;
    width: 100%;
}
.notify-header {
    display: flex;
    justify-content: space-between;
}
.notify-message-header {
    font-size: 10px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 17px;
}
.notify-message-header span {
    display: block;
    font-size: 30px;
}
.notify-message .count-words {
    text-transform: uppercase;
    font-weight: 100;
    line-height: 15px;
}
.notify-message .count-words i {
    
    font-size: 60px;
    
    font-style: normal;
    font-weight: 800;
    opacity: 0.5;
        line-height: 40px;
    
}
.notify-message a {
    color: #333;
    text-decoration: underline;
}

</style>