<template>
    <div id="action-area">
        <div id="action-area-container-place"></div>
        <div id="action-area-container" class="container">
            <slot></slot>
        </div>
        
    </div>
</template>
<script>
export default {
    data(){
        return {  
            timerInterval: null,
            positionTop: 0
        }
    },
    methods: {
        handlePosition() {
            this.positionTop = document.getElementById('action-area-container-place') ? document.getElementById('action-area-container-place').offsetTop : null;
        },
        handleTimer() {
            this.handlePosition();
            // Any code to be executed when the window is scrolled
            if (window.pageYOffset + window.innerHeight >= this.positionTop) {
                document.body.classList.remove('fly-action-area');
            } else {
                document.body.classList.add('fly-action-area');
            }
            //console.log('calling handleScroll');
        } 
    },
    created() {
        //this.timerInterval = window.setInterval(this.handleTimer, 100);
        //window.addEventListener('scroll', this.handleDebouncedScroll);
    },

    beforeDestroy() {
        //window.clearInterval(this.timerInterval);
        // I switched the example from `destroyed` to `beforeDestroy`
        // to exercise your mind a bit. This lifecycle method works too.
        //window.removeEventListener('scroll', this.handleDebouncedScroll);
    }
    
};
</script>
<style scoped>

#action-area-container {
    margin-bottom: 1em;
    padding-top: 2em;
}
body.fly-action-area #action-area-container-place {
   height: 50px;
}
body.fly-action-area #action-area-container {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  margin: 0;
  padding: 1em 15px;
  background: linear-gradient(to bottom,#eeeeee 0,#dddddd 100%);
  z-index: 10;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px -5px 0px #264aa6;
    margin-left: -15px;
    margin-right: -15px;
}

body #action-area-container {
    position: relative;
    overflow: hidden;
}
body #action-area-container:after {
      content: "";
    width: 80%;
    height: 3px;
    top: -15px;
    left: 0;
    position: absolute;
    margin-left: 10%;
    /* padding-top: 25px; */
    -webkit-box-shadow: 0px 4px 20px rgb(50 50 50 / 75%);
    box-shadow: 0px 4px 20px rgb(50 50 50 / 75%);
    border-radius: 50%;
}

</style>