import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import store from "../store";
import { getAccess } from "@/core/services/user.group.access.service";

//actions
export const API_MANAGEMENT_SITES_ACTION_LOAD = "action_admin_management_sites_load";
export const API_MANAGEMENT_GROUPS_ACTION_LOAD = "action_admin_management_groups_load";
export const API_MANAGEMENT_LINKS_ACTION_LOAD = "action_admin_management_links_load";

export const API_MANAGEMENT_SITES_ACTION_SEARCH = "action_admin_management_sites_search";
export const API_MANAGEMENT_GROUPS_ACTION_SEARCH = "action_admin_management_groups_search";
export const API_MANAGEMENT_LINKS_ACTION_SEARCH = "action_admin_management_links_search";

export const API_MANAGEMENT_SITE_ACTION_SAVE = "action_admin_management_site_save";
export const API_MANAGEMENT_GROUP_ACTION_SAVE = "action_admin_management_group_save";
export const API_MANAGEMENT_LINK_ACTION_SAVE = "action_admin_management_link_save";

// muts
export const API_MANAGEMENT_SITES_MUTS_SET_PAGE_PARAMS = "muts_admin_management_sites_set_page_params";
export const API_MANAGEMENT_SITES_MUTS_SET_ERROR = "muts_admin_management_sites_set_error";
export const API_WORDS_MUTS_SET_ERROR = "muts_admin-words_set_error";
export const API_WORDS_MUTS_SET_PAGE_PARAMS = "muts_admin_words_set_page_params";


const state = {
    page_params: {
        localesForLocale: [
            {id: '', locale: '', parrentId: ''}
        ],
        locale: '',
        words: [
            {id: '', locale_id: '', translation_text: '', translated_text: '', comments: ''}
        ],
        page_number: null,
        page_size: null,
        total_items: null

    }, 
    error: {
        isError: null,
        description: '',
        status: ''
    }  
}

const getters = { 
    getAdminManagementSitesPageParams() {
        return state.page_params
    },
    getAdminManagementSitesError() {
        return state.error
    }
};


const actions = { 
    [API_MANAGEMENT_SITES_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/sites/get", params, context)
                    .then(({ data }) => {
                        // console.log('in action API_MANAGEMENT_SITES_ACTION_LOAD');
                        // console.log(data);
                        context.commit(API_MANAGEMENT_SITES_MUTS_SET_PAGE_PARAMS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_MANAGEMENT_GROUPS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/sites_groups/get", params, context)
                    .then(({ data }) => {
                        // console.log('in action API_MANAGEMENT_GROUPS_ACTION_LOAD');
                        // console.log(data);
                        context.commit(API_MANAGEMENT_SITES_MUTS_SET_PAGE_PARAMS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },


    [API_MANAGEMENT_LINKS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/sites_links/get", params, context)
                    .then(({ data }) => {
                        // console.log('in action API_MANAGEMENT_LINKS_ACTION_LOAD');
                        // console.log(data);
                        context.commit(API_MANAGEMENT_SITES_MUTS_SET_PAGE_PARAMS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_MANAGEMENT_SITES_ACTION_SEARCH](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/sites/search", params, context)
                    .then(({ data }) => {
                        // console.log('in action API_MANAGEMENT_SITES_ACTION_SEARCH');
                        // console.log(data);
                        context.commit(API_MANAGEMENT_SITES_MUTS_SET_PAGE_PARAMS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_MANAGEMENT_GROUPS_ACTION_SEARCH](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/sites_groups/search", params, context)
                    .then(({ data }) => {
                        context.commit(API_MANAGEMENT_SITES_MUTS_SET_PAGE_PARAMS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_MANAGEMENT_LINKS_ACTION_SEARCH](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/sites_links/search", params, context)
                    .then(({ data }) => {
                        context.commit(API_MANAGEMENT_SITES_MUTS_SET_PAGE_PARAMS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_MANAGEMENT_SITE_ACTION_SAVE](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/sites/add", params, context)
                    .then(({ data }) => {
                        context.commit(API_MANAGEMENT_SITES_MUTS_SET_PAGE_PARAMS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_MANAGEMENT_GROUP_ACTION_SAVE](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/sites_groups/save", params, context)
                    .then(({ data }) => {
                        context.commit(API_MANAGEMENT_SITES_MUTS_SET_PAGE_PARAMS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },

    [API_MANAGEMENT_LINK_ACTION_SAVE](context, params) {
        return new Promise((resolve, reject) => {
            if(getAccess()) {
                ApiService.post("admin/sites_links/save", params, context)
                    .then(({ data }) => {
                        context.commit(API_MANAGEMENT_SITES_MUTS_SET_PAGE_PARAMS, {data});
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response);
                    });

            }
            else {
                reject();
            }
        });
    },
};


const mutations = {
    [API_MANAGEMENT_SITES_MUTS_SET_PAGE_PARAMS](state, {data}) {
        state.page_params.localesForLocale = data.locales;
    },
    [API_MANAGEMENT_SITES_MUTS_SET_ERROR](state, {data}) {
        state.error.description = data.status.description;
        state.error.isError = true;
        state.error.status = data.status.status;
    },
    [API_WORDS_MUTS_SET_ERROR](state, {data}) {
        state.error.description = data.status.description;
        state.error.isError = true;
        state.error.status = data.status.status;
    },
    [API_WORDS_MUTS_SET_PAGE_PARAMS](state, {data}) {
        state.page_params.words = data.words;
        state.page_params.page_number = data.page_number;
        state.page_params.page_size = data.page_size;
        state.page_params.total_items = data.total_items;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};