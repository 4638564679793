import ApiService from "@/core/services/api.service";

// action types
export const API_ACTION_CROSS_PORTALS_RECORD_STORE_SET = "api_action_cross_portals_record_store_set";
export const API_ACTION_CROSS_PORTALS_RECORD_STORE_GET = "api_action_cross_portals_record_store_get";

// muts

const state = {

}

const getters = { 

}

const actions = {
    [API_ACTION_CROSS_PORTALS_RECORD_STORE_SET](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("record_store/set_record", {record: params}, context)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ response }) => {
                reject(response);
            });
        });
    },
    [API_ACTION_CROSS_PORTALS_RECORD_STORE_GET](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("record_store/get_record/" + params, context)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ response }) => {
                reject(response);
            });
        });
    },
}

const mutations = {

}

export default {
    state,
    actions,
    mutations,
    getters
};