import Vue from "vue";
import Vuex from "vuex";

import ApiAuth from "./api/auth.module";
import AppState from "./app.state.module"; 
import FHQTerms from "./api/admin.fleetHQTerms.module";

import ApiForgotPswd from './api/forgotPassword.module';
import ApiNewRegistration from './api/newRegistration.module';
import UserProfile from './api/userProfile.module';
//locales
import ApiAdminLocales from './api/admin.locales.module';
//user managements
import ApiAdminUsers from "./api/admin.user.module";
// security management
import ApiAdminSecurity from './api/admin.security.module';
import ApiAdminUserGroups from './api/admin.userGroups.module';
import ApiAdminSecurityGroups from './api/admin.securityGroups.module';
//locations
import ApiAdminLocations from "./api/admin.locations.module";
//common
import ApiAdminCommon from "./api/admin.common.module";

import ApiAdminManagementSites from './api/admin.management.sites.module';
import ApiDocumentCenter from './api/admin.documentCenter.module';
//manage tags
import ApiAdminManageTags from './api/admin.manageTags.module';

// fleet central
import ApiFleetCentralSelects from './api/fleet_central/fleetCentral.selects.module';
import ApiFleetCentralHomePage from './api/fleet_central/fleetCentral.homePage.module'

import ApiTranslations from './api/translations.module';

//business solutions
import BusinessSolutions from './api/businessSolutions.module';

//dealer portal
import DealerPortal from './api/dealerPortal.module';

// heatmap
import DealerHeatMap from './api/heatmap/dealer.heatmap.module';
import FleetHeatMap from './api/heatmap/fleet.heatmap.module';
import DetailsHeatMap from './api/heatmap/details.heatmap.module';
//favorite reports
import FavoriteReports from './api/favoriteReports/favoriteReports.module';
import FavoriteReportsAdd from './api/favoriteReports/favoriteReportsAdd.module';

//gmc user managements
import ApiGmcAccessManagment from "./api/gmc.module";

// records store
import RecordsStoreModule from "./api/records.store.module";

// selects
import SelectsModule from "./api/selects";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ApiAuth,
    AppState,
    ApiAdminLocales,
    ApiAdminUsers,
    ApiAdminSecurity,
    ApiAdminManagementSites,
    ApiForgotPswd,
    ApiNewRegistration,
    UserProfile,
    ApiAdminUserGroups,
    ApiAdminSecurityGroups,
    ApiAdminLocations,
    ApiAdminCommon,
    FHQTerms,    
    ApiDocumentCenter,

    ApiFleetCentralSelects,
    ApiFleetCentralHomePage,
    ApiAdminManageTags,
    ApiTranslations,

    BusinessSolutions,
    
    DealerPortal,

    DealerHeatMap,
    FleetHeatMap,
    DetailsHeatMap,
    FavoriteReports,
    FavoriteReportsAdd,
    
    ApiGmcAccessManagment,

    RecordsStoreModule,

    SelectsModule
  }
});